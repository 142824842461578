import React, { useEffect, useState } from 'react';
import classes from "./styles/firstSection.module.css";
import Img from "../assets/about/t4.jpg";
const ThirdSection = () => {
    const [opacity, setOpacity] = useState(0);

    const handleScroll = () => {
      const scrollTop = window.pageYOffset;
      const windowHeight = window.innerHeight;
      const element = document.querySelector(".scroll-container");
    
      // Check if the element exists
      if (!element) return;
    
      const elementTop = element.getBoundingClientRect().top + scrollTop - 100;
      const elementHeight = element.offsetHeight;
      const startFade = elementTop - windowHeight;
      const endFade = elementTop + elementHeight;
    
      if (scrollTop > startFade && scrollTop < endFade) {
        const opacityValue = (scrollTop - startFade) / (endFade - startFade);
        setOpacity(opacityValue);
      } else if (scrollTop >= endFade) {
        setOpacity(1);
      } else {
        setOpacity(0);
      }
    };
  
    useEffect(() => {
      window.addEventListener("scroll", handleScroll);
      return () => window.removeEventListener("scroll", handleScroll);
    }, []);


  return (
    <>
    <div className={classes.container}>
        <div className={classes.workCon}>
            <div className={`scroll-container ${classes.background}`} style={{ backgroundColor: `rgba(255, 238, 204, ${opacity})` }}></div>
            <div className={classes.descriptionCon} >
                <div className={classes.details}>
                <p className={classes.title}>Our Mission</p>
                <p className={classes.para}>At Wiraa, our mission is to empower businesses and individuals by providing exceptional WordPress solutions that drive growth and success. We strive to deliver high-quality, customized services in website development, maintenance, modifications, and technical SEO that help our clients stand out in the digital landscape.<br/><br/>We believe in building strong, long-lasting relationships based on trust, transparency, and a commitment to excellence. Our goal is to be more than just a service provider—we aim to be a reliable partner in your journey towards achieving digital success.</p>
                </div>           
            </div>
        </div>

        <div className={classes.workCon2}>
            <img src={Img} className={classes.Img} alt='about-us-img'/>
        </div>
        
        
    </div>
    </>
  );
}

export default ThirdSection;