import React, { useState } from 'react'
import classes from "./styles/FAQ.module.css";
import plus from "../assets/faq/add.png";
import minus from "../assets/faq/minus.png";
const FAQ = () => {
    const [box1 , setBox1] = useState(false);
    const [box2 , setBox2] = useState(false);
    const [box3 , setBox3] = useState(false);
    const [box4 , setBox4] = useState(false);
    const [box5 , setBox5] = useState(false);
    const [box6 , setBox6] = useState(false);
    const [box7 , setBox7] = useState(false);

    const handlebox1 = () => {
        setBox1(!box1);
    }
    const handlebox2 = () => {
        setBox2(!box2);
    }

    const handlebox3 = () => {
        setBox3(!box3);
    }
    const handlebox4 = () => {
        setBox4(!box4);
    }
    const handlebox5 = () => {
        setBox5(!box5);
    }

    const handlebox6 = () => {
        setBox6(!box6);
    }
    const handlebox7 = () => {
        setBox7(!box7);
    }


  return (
    <div className={classes.Container}>

        <div className={box1 ? classes.boxOpen : classes.box} onClick={handlebox1}>
            <div className={classes.upperCon}>
                <div>What’s your process for developing a new WordPress website?</div>
                <div className={classes.logoCon}><img src={box1 ? minus : plus} alt='plus-minus-icon'/></div>
            </div>
            <div className={classes.lowerCon}>
                <div>Our new website development process includes an initial consultation to understand your goals, followed by design mockups based on your specifications. Once approved, we implement the design using WordPress, ensuring a responsive and user-friendly experience.</div>
            </div>
        </div>


        <div className={box2 ? classes.boxOpen : classes.box} onClick={handlebox2}>
            <div className={classes.upperCon}>
                <div>Do you offer e-commerce website development?</div>
                <div className={classes.logoCon}><img src={box2 ? minus : plus} alt='plus-minus-icon'/></div>
            </div>
            <div className={classes.lowerCon}>
                <div>Yes! We specialize in building e-commerce websites using WooCommerce, which allows you to manage products, payments, and shipping seamlessly. Our team ensures your online store is optimized for conversions and provides an excellent user experience.</div>
            </div>
        </div>



        <div className={box3 ? classes.boxOpen : classes.box} onClick={handlebox3}>
            <div className={classes.upperCon}>
                <div>How long does it take to develop a basic WordPress website?</div>
                <div className={classes.logoCon}><img src={box3 ? minus : plus} alt='plus-minus-icon'/></div>
            </div>
            <div className={classes.lowerCon}>
                <div>Typically, each page takes around 1 to 3 days to develop. On average, developing a basic WordPress website takes about 1 to 3 weeks, depending on the complexity and number of pages required.</div>
            </div>
        </div>

        <div className={box4 ? classes.boxOpen : classes.box} onClick={handlebox4}>
            <div className={classes.upperCon}>
                <div>Can I use custom plugins on my WordPress site?</div>
                <div className={classes.logoCon}><img src={box4 ? minus : plus} alt='plus-minus-icon'/></div>
            </div>
            <div className={classes.lowerCon}>
                <div>Absolutely! We can integrate a wide range of plugins to enhance your site's functionality, from SEO tools and security plugins to performance optimizers and e-commerce solutions. If you have specific plugins in mind, just let us know!</div>
            </div>
        </div>

        <div className={box5 ? classes.boxOpen : classes.box} onClick={handlebox5}>
            <div className={classes.upperCon}>
                <div>What kind of ongoing support do you offer after the site is launched?</div>
                <div className={classes.logoCon}><img src={box5 ? minus : plus} alt='plus-minus-icon'/></div>
            </div>
            <div className={classes.lowerCon}>
                <div>We provide various support plans, including maintenance, updates, and troubleshooting, to ensure your site remains secure and performs optimally.</div>
            </div>
        </div>



        <div className={box6 ? classes.boxOpen : classes.box} onClick={handlebox6}>
            <div className={classes.upperCon}>
                <div>Can you help with the migration of my existing site to WordPress?</div>
                <div className={classes.logoCon}><img src={box6 ? minus : plus} alt='plus-minus-icon'/></div>
            </div>
            <div className={classes.lowerCon}>
                <div>Yes! We offer migration services to smoothly transition your existing website to WordPress. Our team will ensure that all your content, including images and data, is securely transferred without any loss.</div>
            </div>
        </div>

        <div className={box7 ? classes.boxOpen : classes.box} onClick={handlebox7}>
            <div className={classes.upperCon}>
                <div>Do you provide training for managing my WordPress site?</div>
                <div className={classes.logoCon}><img src={box7 ? minus : plus} alt='plus-minus-icon'/></div>
            </div>
            <div className={classes.lowerCon}>
                <div>Absolutely! We offer training sessions to help you understand how to manage your site, update content, and utilize any plugins effectively.</div>
            </div>
        </div>
    </div>
  )
}

export default FAQ
