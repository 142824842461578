import React from 'react'
import classes from "./Styles/portfolio1.module.css";
import Img1 from "../assets/Portfolio1/1.png";
import Img2 from "../assets/Portfolio1/2.png";
import Img3 from "../assets/Portfolio1/3.png";
import Img4 from "../assets/Portfolio1/4.png";
import Img5 from "../assets/Portfolio1/5.png";
import Img6 from "../assets/Portfolio1/6.png";
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
const Portfolio1 = () => {
  return (
    <div className={classes.container}>
        <div className={classes.NavbarCon}><Navbar contactSection="contactSection" faqSection="faqSection" packageSection="packageSection"/></div>
        
       
        <div className={classes.firstCon}>
        <div className={classes.firstImgCon}><img src={Img1} alt='background-img'/></div>
     
      <div className={classes.detailCon}>
        <div className={classes.slipCon}>
            <div className={classes.nameCon}>
                <div className={classes.name}>Project:</div>
                <div className={classes.Subname}>Tej Patta</div>
            </div>
            <div className={classes.nameCon}>
                <div className={classes.name}>Type:</div>
                <div className={classes.Subname}>Website</div>
            </div>
            <div className={classes.nameCon}>
                <div className={classes.name}>Deliverables:</div>
                <div className={classes.Subname}>UI Design, Development</div>
            </div>
            <div className={classes.nameCon}>
                <div className={classes.name}>Time:</div>
                <div className={classes.Subname}>1 Week</div>
            </div>
            <div className={classes.nameCon}>
                <div className={classes.name}>Package:</div>
                <div className={classes.Subname}>Starter</div>
            </div>
        </div>
       
       
        <div className={classes.line}></div>
        
        
        <div className={classes.descriptionCon}>
            <div className={classes.desTitle}>Tej Patta</div>
            <div className={classes.desPara}>Tej Patta Cloud Kitchen is a recent project focused on delivering authentic Indian cuisine directly to customers' homes. Our team designed a user-friendly online platform that showcases a diverse menu, featuring traditional dishes crafted with quality ingredients. The project emphasizes convenience and accessibility, allowing food lovers to enjoy homemade flavors with ease.</div>
        </div>
      </div></div>


      <div className={classes.ssContainer}></div>
      <div className={classes.viewTitle}>Desktop View</div>
      <div className={classes.ssContainer1}>
        <div className={`${classes.ssCon} ${classes.ssCon1}`}><img src={Img1} className={classes.img1} alt='portfolio-screenshot'/></div>
      </div>
      <div className={classes.ssContainer1}>
        <div className={`${classes.ssCon} ${classes.ssCon1}`}><img src={Img2} className={classes.img1} alt='portfolio-screenshot'/></div>
      </div>

      <div className={classes.viewTitle}>Mobile View</div>
      <div className={classes.ssContainer2}>
      <div className={`${classes.ssCon} ${classes.ssCon2}`}><img src={Img3} className={classes.img2} alt='portfolio-screenshot'/></div>
      <div className={`${classes.ssCon} ${classes.ssCon3}`}><img src={Img4} className={classes.img2} alt='portfolio-screenshot'/></div>
      </div>

      <div className={classes.viewTitle}>Tablet View</div>
      <div className={classes.ssContainer3}>
      <div className={`${classes.ssCon} ${classes.ssCon4}`}><img src={Img5} className={classes.img2} alt='portfolio-screenshot'/></div>
      <div className={`${classes.ssCon} ${classes.ssCon5}`}><img src={Img6} className={classes.img2} alt='portfolio-screenshot'/></div>
      </div>

      <div className={classes.footerCon}>
      <Footer contactSection="contactSection" faqSection="faqSection" packageSection="packageSection"/>
    </div>
    </div>
  )
}

export default Portfolio1;
