import React, { useEffect, useState } from 'react';
import classes from "./styles/Testimonial.module.css";
import keepImg from "../assets/shapes/keep.png";
const Testimonial = () => {
    const imageKey = "sp=r&st=2023-12-20T12:12:53Z&se=2029-12-30T20:12:53Z&sv=2022-11-02&sr=c&sig=hhS%2BZMXvTby3es%2FO%2FGHnY%2BF8VoR2jc5f4bx%2B8TGu5Xg%3D";
    const [opacity, setOpacity] = useState(0);

    const [slideView, setSlideView] = useState("f"); 

    const Clients = [
        {
          name: "Mahendra Panchal",
          img: "https://wiraastorage.blob.core.windows.net/images/Emiexpess.jpeg",
          logo: "https://wiraastorage.blob.core.windows.net/images/Emiexpresslogo.png",
          position: "CEO",
          description: "Wiraa helped me find the perfect freelancer for my project. The process was smooth, and the results exceeded my expectations. Highly recommend!",
        },
        {
          name: "Chandan Soni",
          img: "https://wiraastorage.blob.core.windows.net/images/techhelper.jpeg",
          logo: "https://wiraastorage.blob.core.windows.net/images/Techhelperlogo.png",
          position: "Marketing Head",
          description: "I've been using Wiraa for all my freelance needs, and I couldn't be happier. The platform is user-friendly, and the talent pool is exceptional.",
        },
        {
          name: "Aakash Khurapa",
          img: "https://wiraastorage.blob.core.windows.net/images/Smartwarehouse.png",
          logo: "https://wiraastorage.blob.core.windows.net/images/Smartwarehouselogo.png",
          position: "CEO",
          description: "Wiraa has been a game-changer for our business. The quality of freelancers we've found has been outstanding, and the platform makes it easy to manage projects.",
        },
        {
          name: "Ankit Thakur",
          img: "https://wiraastorage.blob.core.windows.net/images/Yolmate.jpeg",
          logo: "https://wiraastorage.blob.core.windows.net/images/Yolmatelogo.png",
          position: "CEO",
          description: "I've tried several freelancing platforms, but Wiraa stands out. The communication with freelancers is seamless, and the project management tools are top-notch.",
        },
        {
          name: "Deepesh Ojha",
          img: "https://wiraastorage.blob.core.windows.net/images/Gunagya.png",
          logo: "https://wiraastorage.blob.core.windows.net/images/Gunagyalogo.png",
          position: "CEO",
          description: "Wiraa has become my go-to platform for hiring freelancers. The variety of talent available and the support from the team make it a great choice for any project.",
        }
        
      ]

    const handleScroll = () => {
        const scrollTop = window.pageYOffset;
        const windowHeight = window.innerHeight;
        const element = document.querySelector(".scroll-container");
      
        // Check if the element exists
        if (!element) return;
      
        const elementTop = 3100;
        const elementHeight = element.offsetHeight;
        const startFade = elementTop - windowHeight;
        const endFade = elementTop + elementHeight;
      
        if (scrollTop > startFade && scrollTop < endFade) {
          const opacityValue = (scrollTop - startFade) / (endFade - startFade);
          setOpacity(opacityValue);
        } else if (scrollTop >= endFade) {
          setOpacity(1);
        } else {
          setOpacity(0);
        }
      };
      

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);


  return (
    <>
    <div className={`scroll-container ${classes.container}`}>
        <div className={classes.workCon}>
            <div className={`${classes.background}`} style={{ background: `rgba(136, 221, 255, ${opacity})` }}></div>
            <div className={classes.descriptionCon} >
                <div className={classes.details}>
                <p className={classes.title}>Stories of Success</p>
                <p className={classes.subtitle}>See the difference we made</p>
                <p className={classes.para}>Hear from the businesses and individuals who've trusted us with their needs. From smooth project execution to standout results, our clients share their experiences and success stories. Real feedback, real impact—see how we’ve made a difference!</p>
                </div>           
            </div>
        </div>

        {/* <div className={classes.mainCon}>
        {Clients.map((client, i) => {
           return <div className={classes.clientContainer} key={i}>
            <div className={classes.logoCon}> <img className={classes.logo} src={`${client.logo}?${imageKey}`}/></div>
             
              <p className={classes.clientdescription}>{client.description}</p>

              <div className={classes.nameCon}>
                <img src={`${client.img}?${imageKey}`} />
                <div className={classes.username}>
                    <h5>{client.name}</h5>
                    <p>{client.position}</p>
                </div>
              </div>
              
          </div>
        })}       
        </div> */}
        <div className={classes.mainCon}>
        {slideView === "f" ? <div className={classes.clientContainer}>
            <div className={classes.logoCon}> <img className={classes.logo} src={`${Clients[0]?.logo}?${imageKey}`} alt='company-logo'/></div>
             
              <p className={classes.clientdescription}>{Clients[0]?.description}</p>

              <div className={classes.nameCon}>
                <img src={`${Clients[0]?.img}?${imageKey}`} alt='user-img'/>
                <div className={classes.username}>
                    <h5>{Clients[0]?.name}</h5>
                    <p>{Clients[0]?.position}</p>
                </div>
              </div>
              
          </div> : <></>}
          {slideView === "s" ? <div className={classes.clientContainer}>
            <div className={classes.logoCon}> <img className={classes.logo} src={`${Clients[1]?.logo}?${imageKey}`} alt='company-logo'/></div>
             
              <p className={classes.clientdescription}>{Clients[1]?.description}</p>

              <div className={classes.nameCon}>
                <img src={`${Clients[1]?.img}?${imageKey}`} alt='user-img'/>
                <div className={classes.username}>
                    <h5>{Clients[1]?.name}</h5>
                    <p>{Clients[1]?.position}</p>
                </div>
              </div>
              
          </div> : <></>}
          {slideView === "t" ? <div className={classes.clientContainer}>
            <div className={classes.logoCon}> <img className={classes.logo} src={`${Clients[4]?.logo}?${imageKey}`} alt='company-logo'/></div>
             
              <p className={classes.clientdescription}>{Clients[4]?.description}</p>

              <div className={classes.nameCon}>
                <img src={`${Clients[4]?.img}?${imageKey}`} alt='user-img'/>
                <div className={classes.username}>
                    <h5>{Clients[4]?.name}</h5>
                    <p>{Clients[4]?.position}</p>
                </div>
              </div>
              
          </div> : <></>}
          
          </div>
          <div className={classes.BtnCon}>
            <button className={slideView === "f" ? classes.activeBtn : classes.btn} onClick={() => setSlideView("f")}></button>
            <button className={slideView === "s" ? classes.activeBtn : classes.btn} onClick={() => setSlideView("s")}></button>
            <button className={slideView === "t" ? classes.activeBtn : classes.btn} onClick={() => setSlideView("t")}></button>
          </div>
          <div className={classes.BackCon}><img src={keepImg} alt='keep-img'/></div>
    </div>
    </>
  );
}

export default Testimonial;