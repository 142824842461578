import React, {useState } from 'react';
import classes from "./Styles/projectForm.module.css";
import Navbar from '../components/Navbar';
function ProjectForm() {
  const [firstName, setFirstName] = useState();
  const [lastName, setLastName] = useState();
  const [email, setEmail] = useState();
  const [mobile, setMobile] = useState();
  const [message, setMessage] = useState();
  const [features, setFeatures] = useState({
    slider: false,
    gallery: false,
    portfolio: false,
    testimonials: false,
    teamPage: false,
    eventCalendar: false,
    videos: false,
    paypal: false,
    loginPortal: false,
    contactForm: false
  });

  const handleChange = (event) => {
    const { name, checked } = event.target;
    setFeatures((prevFeatures) => ({
      ...prevFeatures,
      [name]: checked
    }));
  };

  const handleSubmit = () => {
    setFirstName("");
    setLastName("");
    setEmail("");
    setMobile("");
    setMessage("");
  }

  return <div className={classes.container}>
    
    <Navbar contactSection="contactSection" faqSection="faqSection" packageSection="packageSection"/>
    <div className={classes.background}></div>
    <div className={classes.firstDiv}>
      <div className={classes.heading}>Custom Designs Delivered</div>
      <div className={classes.title}>Request a Custom WordPress Web Development Quote</div>
      <div className={classes.subTitle}>Ready to boost your online presence? Request a custom WordPress website quote by filling out the form. Whether it's a basic site or an online store, we’ll tailor a solution to fit your needs and budget. Let's build a website that helps your business thrive!</div>
    </div>

    <div className={classes.formCon}>
    <div className={classes.formInnerCon}>
        <form className={classes.form}>
            <div className={classes.formtitle}>Fill up business requirement</div>
            <div className={classes.formSubTitle}>Get a Tailored Solution for Your Business Needs</div>
            
            <div className={classes.divCon}>
              <div className={classes.fieldCon}>
                <span>First name *</span>
                <input required type='text' value={firstName} placeholder="Enter your first name" onChange={(e) => setFirstName(e.target.value)}/>
              </div>
              <div className={classes.fieldCon}>
                <span>Last name *</span>
                <input required type='text' value={lastName} placeholder="Enter your last name" onChange={(e) => setLastName(e.target.value)}/>
              </div>
            </div>

            <div className={classes.divCon}>
              <div className={classes.fieldCon}>
                <span>Email *</span>
                <input required type='email' value={email} placeholder="Your Email" onChange={(e) => setEmail(e.target.value)}/>
              </div>
              <div className={classes.fieldCon}>
                <span>Phone *</span>
                <input required type='number' value={mobile} placeholder="Mobile" onChange={(e) => setMobile(e.target.value)}/>
              </div>
            </div>

            <div className={classes.divCon}>
              <div className={classes.fieldCon}>
                <span>Business Name *</span>
                <input required type='text' value={email} placeholder="Your Business Name" onChange={(e) => setEmail(e.target.value)}/>
              </div>
              <div className={classes.fieldCon}>
                <span>Industry *</span>
                <input required type='text' value={mobile} placeholder="Your Business type" onChange={(e) => setMobile(e.target.value)}/>
              </div>
            </div>

            <div className={classes.divCon}>
            <div className={classes.fieldCon}>
                <span>Project Budget *</span>
                <select>
                  <option value="">Select Budget</option>
                  <option value="$500-$1000">$500-$1000</option>
                  <option value="$1000-$2000">$1000-$2000</option>
                  <option value="Over $2000">Over $2000</option>
                </select>
              </div>
              <div className={classes.fieldCon}>
                <span>Currently have a website? *</span>
                <select>
                  <option value="">Choose an option</option>
                  <option value="yes">Yes</option>
                  <option value="no">No</option>
                </select>
              </div>
            </div>

            <div className={classes.formtitle2}>Content Information</div>


            <div className={classes.divCon}>
            <div className={classes.fieldCon}>
                <span>Do you have a logo? *</span>
                <select>
                  <option value="">Choose an option</option>
                  <option value="yes">Yes</option>
                  <option value="no">No</option>
                </select>
              </div>
              <div className={classes.fieldCon}>
                <span>Do you want a blog on your website?*</span>
                <select>
                  <option value="">Choose an option</option>
                  <option value="yes">Yes</option>
                  <option value="no">No</option>
                </select>
              </div>
            </div>

            <div className={classes.divCon}>
            <div className={classes.fieldCon}>
                <span>Will you be providing the written content? *</span>
                <select>
                  <option value="">Choose an option</option>
                  <option value="yes">Yes</option>
                  <option value="no">No</option>
                </select>
              </div>
              <div className={classes.fieldCon}>
                <span>Will you be providing the images? *</span>
                <select>
                  <option value="">Choose an option</option>
                  <option value="yes">Yes</option>
                  <option value="no">No</option>
                </select>
              </div>
            </div>

            <div className={classes.divCon}>
            <div className={classes.fieldCon}>
                <span>Will you require WordPress training? *</span>
                <select>
                  <option value="">Choose an option</option>
                  <option value="yes">Yes</option>
                  <option value="no">No</option>
                </select>
              </div>
              <div className={classes.fieldCon}>
                <span>What is the best time to contact you? *</span>
                <select>
                  <option value="">Choose an option</option>
                  <option value="Morning">Morning</option>
                  <option value="Afternoon">Afternoon</option>
                  <option value="Evening">Evening</option>
                </select>
              </div>
            </div>



            
            <div className={classes.formtitle2}>Select Features</div>
            <div className={classes.checkboxCon}>
        <div className={classes.label}>
        <input
          type="checkbox"
          name="slider"
          checked={features.slider}
          onChange={handleChange}
        />
        <span>Slider</span>
      </div>
      <div className={classes.label}>
        <input
          type="checkbox"
          name="gallery"
          checked={features.gallery}
          onChange={handleChange}
        />
        <span>Gallery</span>
      </div>
      <div className={classes.label}>
        <input
          type="checkbox"
          name="portfolio"
          checked={features.portfolio}
          onChange={handleChange}
        />
        <span>Portfolio</span>
      </div>
      <div className={classes.label}>
        <input
          type="checkbox"
          name="testimonials"
          checked={features.testimonials}
          onChange={handleChange}
        />
        <span>Testimonials</span>
      </div>
      <div className={classes.label}>
        <input
          type="checkbox"
          name="teamPage"
          checked={features.teamPage}
          onChange={handleChange}
        />
        <span>Team/Employee Page</span>
      </div>
      <div className={classes.label}>
        <input
          type="checkbox"
          name="eventCalendar"
          checked={features.eventCalendar}
          onChange={handleChange}
        />
        <span>Event Calendar</span>
      </div>
      <div className={classes.label}>
        <input
          type="checkbox"
          name="videos"
          checked={features.videos}
          onChange={handleChange}
        />
        <span>YouTube/Vimeo Videos</span>
      </div>
      <div className={classes.label}>
        <input
          type="checkbox"
          name="paypal"
          checked={features.paypal}
          onChange={handleChange}
        />
        <span>PayPal</span>
      </div>
      <div className={classes.label}>
        <input
          type="checkbox"
          name="loginPortal"
          checked={features.loginPortal}
          onChange={handleChange}
        />
        <span>Login Portal/Client Area</span>
      </div>
      <div className={classes.label}>
        <input
          type="checkbox"
          name="contactForm"
          checked={features.contactForm}
          onChange={handleChange}
        />
        <span>Contact Form</span>
      </div>
    </div>





        <div className={classes.formtitle2}>Share Details</div>

            <div className={classes.fieldCon}>
                <span>Describe your project...</span>
                <textarea placeholder="Project Description" value={message} rows={5} onChange={(e) => setMessage(e.target.value)}/>
              </div>
              <div className={classes.fieldCon}>
                <span>Website examples and describe what you like...</span>
                <textarea placeholder="Reference websites" value={message} rows={5} onChange={(e) => setMessage(e.target.value)}/>
              </div>
            <input className={classes.btn} type="submit" value="Submit" onClick={handleSubmit}/>
        </form>
      </div>
    </div>
  </div>;
}

export default ProjectForm;