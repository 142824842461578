import React, { useEffect, useState } from 'react';
import classes from "./Styles/maintenance.module.css";
import Navbar from '../components/Navbar';
import Img1 from "../assets/maintenance/1.png";
import Img2 from "../assets/maintenance/2.png";

import database from "../assets/maintenance/database.png";
import ssl from "../assets/maintenance/ssl.png";
import seo from "../assets/maintenance/seo.png";
import security from "../assets/maintenance/security.png";
import support from "../assets/maintenance/support.png";
import update from "../assets/maintenance/update.png";

import grow from "../assets/maintenance/growth.png";
import click from "../assets/maintenance/click.png";
import chat from "../assets/maintenance/chat.png";

import check from "../assets/check.svg";
import Footer from '../components/Footer';
import MaintenanceFaq from '../aboutSections/MaintenanceFaq';

function Maintenance() {
  const [opacity, setOpacity] = useState(0);
  const handleButtonClick1 = () => {
    const queryParams = new URLSearchParams({
      color: '#EFEFEF',
      price: "49",
      plan: 'Standard',
      time: '/mo'
    }).toString();
    window.open(`/#/checkout?${queryParams}`, '_blank');
  };

  const handleButtonClick2 = () => {
    const queryParams = new URLSearchParams({
      color: '#FFF0E0',
      price: "99",
      plan: 'Advanced',
      time: '/mo'
    }).toString();
    window.open(`/#/checkout?${queryParams}`, '_blank');
  };

  useEffect(() => {
    document.title = "Wordpress Website Maintenance";
    const metaDescription = document.querySelector('meta[name="description"]');
    if (metaDescription) {
      metaDescription.setAttribute('content', 'Comprehensive WordPress Website Maintenance for Optimal Performance.');
    } else {
      const newMetaDescription = document.createElement('meta');
      newMetaDescription.name = 'description';
      newMetaDescription.content = 'Comprehensive WordPress Website Maintenance for Optimal Performance.';
      document.getElementsByTagName('head')[0].appendChild(newMetaDescription);
    }
  }, []);


  const handleScroll = () => {
    const scrollTop = window.pageYOffset;
    const windowHeight = window.innerHeight;
    const element = document.querySelector(".scroll-container");
  
    // Check if the element exists
    if (!element) return;
  
    const elementTop = element.getBoundingClientRect().top + scrollTop - 100;
    const elementHeight = element.offsetHeight;
    const startFade = elementTop - windowHeight;
    const endFade = elementTop + elementHeight;
  
    if (scrollTop > startFade && scrollTop < endFade) {
      const opacityValue = (scrollTop - startFade) / (endFade - startFade);
      setOpacity(opacityValue);
    } else if (scrollTop >= endFade) {
      setOpacity(1);
    } else {
      setOpacity(0);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const scrollToPackage = () => {
    const element = document.getElementById('priceSection');
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  };

  // useEffect(() => {
  //   const timeoutId = setTimeout(() => {
  //     import('./canvas.js');
  //     console.log("This function is called!");
  //   }, 100);
  //   return () => clearTimeout(timeoutId);
  // }, []);

  return (

  <div className={classes.container}>
  <Navbar contactSection="contactSection" faqSection="faqSection" packageSection="packageSection"/>
  <div className={classes.background}><canvas id="test"></canvas></div>
  <div className={classes.firstDiv}>
    <div className={classes.heading}>Expert Care Always</div>
    <div className={classes.title}>Comprehensive WordPress Website Maintenance for Optimal Performance</div>
    <div className={classes.subTitle}>Keep your WordPress site running smoothly with our expert maintenance services. We handle updates, security checks, and performance optimizations, ensuring your site stays secure and efficient. Let us manage the technical details so you can focus on your business.</div>
    <div className={classes.btnDiv}><button className={classes.contactBtn} onClick={scrollToPackage}>See Plans</button></div>
  </div>


  {/* -----------------------What is website maintenance section ------------------------ */}

  <div className={classes.vector1Con}>
    <div className={classes.vector1left}>
      <img src={Img1} alt='vector-img'/>
    </div>
    <div className={classes.vector1right}>
      <div className={classes.question}>What is website maintenance?</div>
      <div className={classes.ans}>Website maintenance involves regularly updating and managing a website to ensure it runs smoothly and remains secure. This includes tasks like updating software, fixing bugs, optimizing performance, and backing up data.</div>
    </div>
  </div>

  {/* -----------------------Why is  website maintenance important section ------------------------ */}


  <div className={classes.vector2Con}>
    <div className={classes.vector2left}>
      <div className={classes.question}>Why is website maintenance important?</div>
      <div className={classes.ans}>Website maintenance is crucial to prevent security vulnerabilities, ensure fast load times, and provide a seamless user experience. Regular updates help avoid issues like downtime or broken features, while performance optimizations keep the site efficient and engaging.</div>
    </div>
    <div className={classes.vector2right}>
      <img src={Img2} alt='vector-img'/>
    </div>
  </div>


    



  {/* -----------------------Benfits container section ------------------------ */}

  <div className={`scroll-container ${classes.featureCon}`}>
    <div className={classes.featureInnerCon} style={{ background: `rgba(204,237,250, ${opacity})` }}>
      <div className={classes.featureTitle}>Maintain, Optimize, Protect & Thrive!</div>



        <div className={classes.featureDivLine}>
          <div className={classes.featureDiv}>
            <div className={classes.featureLogo}><img src={update} alt='daily-updates-icon'/></div>
            <div className={classes.featureName}>Daily Updates</div>
            <div className={classes.featurePara}>Stay updated with the latest WordPress core & plugin versions</div>
          </div>


          <div className={classes.featureDiv}>
            <div className={classes.featureLogo}><img src={database} alt='daily-backup-icon'/></div>
            <div className={classes.featureName}>Daily Backups & Bug Fixes</div>
            <div className={classes.featurePara}>Ensure your data is safe with daily backups & get quick fixes</div>
          </div>
        
          <div className={classes.featureDiv}>
            <div className={classes.featureLogo}><img src={security} alt='security-icon'/></div>
            <div className={classes.featureName}>24/7 Security Monitoring</div>
            <div className={classes.featurePara}>Round-the-clock protection with malware scans & firewall defence</div>
          </div>
          </div>

        <div className={classes.featureDivLine}>
          <div className={classes.featureDiv}>
            <div className={classes.featureLogo}><img src={ssl} alt='high-speed-icon'/></div>
            <div className={classes.featureName}>High-Speed Hosting & SSL</div>
            <div className={classes.featurePara}>Fast, secure hosting with SSL encryption for enhanced performance</div>
          </div>

          <div className={classes.featureDiv}>
            <div className={classes.featureLogo}><img src={seo} alt='seo-icon'/></div>
            <div className={classes.featureName}>SEO & Media Optimization</div>
            <div className={classes.featurePara}>Improve rankings with SEO optimization & optimized media</div>
          </div>

          <div className={classes.featureDiv}>
            <div className={classes.featureLogo}><img src={support} alt='support-icon'/></div>
            <div className={classes.featureName}>Priority Support</div>
            <div className={classes.featurePara}>Get fast help with dedicated 24/7 priority technical support</div>
          </div>
        </div>

        </div>
        </div>

        {/* -----------------------Steps ------------------------ */}

    <div className={classes.stepCon}>
      
      <div className={classes.stepTitle}>How It Works</div>
      <div className={classes.stepSubTitle}>Maintain your WordPress website effortlessly in just 3 simple steps</div>
      <div className={classes.stepInnerCon}>
      <div className={classes.step1Div}>
        <div className={classes.stepImgCon}><img src={click} alt='setp-icon'/></div>
        <div className={classes.stepSubname}>Step 1</div>
        <div className={classes.stepName}>Choose a Maintenance Plan</div>
        <div className={classes.stepPara}>Pick the perfect package for your site’s needs from our range of affordable maintenance service options.</div>
      </div>


      <div className={classes.step1Div}>
        <div className={classes.stepImgCon}><img src={chat} alt='step-icon'/></div>
        <div className={classes.stepSubname}>Step 2</div>
        <div className={classes.stepName}>Chat with Us & Onboard</div>
        <div className={classes.stepPara}>We’ll chat with you to understand your website and set up automated tasks for a smooth and secure experience.</div>
      </div>

      <div className={classes.step1Div}>
        <div className={classes.stepImgCon}><img src={grow} alt='step-icon'/></div>
        <div className={classes.stepSubname}>Step 3</div>
        <div className={classes.stepName}>Save Time. Grow Your Business</div>
        <div className={classes.stepPara}>Relax knowing your WordPress site is in expert hands, with regular maintenance keeping it running flawlessly.</div>
      </div>

    </div>
  </div>


{/* -----------------------Packages section ------------------------ */}

<div className={`${classes.packageCon} `} id='priceSection'>
<div className={classes.packageShape}><span>Best Value</span></div>
  <div className={`${classes.packageDiv}`}>
    <div className={classes.upperPackage}>
    <div className={classes.packageName}>Standard</div>
    <div className={classes.packagePrice}>$49/mo</div>
    <div className={classes.packageBtnCon}><button className={classes.packageBtn} onClick={handleButtonClick1}>Get Started</button></div>
    </div>
    <div className={classes.lowerPackage}>
    <div className={classes.packageItem}><img src={check} alt='right-tick-icon'/><span><b>Weekly</b> WP Core & Plugin Updates</span></div>
    <div className={classes.packageItem}><img src={check} alt='right-tick-icon'/><span><b>Weekly</b> Backups</span></div>
    <div className={classes.packageItem}><img src={check} alt='right-tick-icon'/><span><b>1 Hour</b> Bug Fixes & Small Changes</span></div>
    <div className={classes.packageItem}><img src={check} alt='right-tick-icon'/><span><b>1</b> Business Email</span></div>
    <div className={classes.packageItem}><img src={check} alt='right-tick-icon'/><span>1 Month <b>Secure</b> Hosting</span></div>
    <div className={classes.packageItem}><img src={check} alt='right-tick-icon'/><span>SSL Certificate</span></div>
    <div className={classes.packageItem}><img src={check} alt='right-tick-icon'/><span>24-Hour Malware Scanning & Optimization</span></div>
    <div className={classes.packageItem}><img src={check} alt='right-tick-icon'/><span>Broken Link Monitor</span></div>
    <div className={classes.packageItem}><img src={check} alt='right-tick-icon'/><span>24x7 Firewall Protection</span></div>
    <div className={classes.packageItem}><img src={check} alt='right-tick-icon'/><span>24/7 Uptime Monitor</span></div>
    </div>
  </div>


  <div className={`${classes.packageDiv} ${classes.p2}`}>
    <div className={classes.upperPackage2}>
    <div className={classes.packageName2}>Advanced</div>
    <div className={classes.packagePrice2}>$99/mo</div>
    <div className={classes.packageBtnCon}><button className={classes.packageBtn2} onClick={handleButtonClick2}>Get Started</button></div>
    </div>
    <div className={classes.lowerPackage}>
    <div className={classes.packageItem}><img src={check} alt='right-tick-icon'/><span><b>Daily</b> WP Core & Plugin Updates</span></div>
    <div className={classes.packageItem}><img src={check} alt='right-tick-icon'/><span><b>Daily</b> Backups</span></div>
    <div className={classes.packageItem}><img src={check} alt='right-tick-icon'/><span><b>2 Hour</b> Bug Fixes & Small Changes</span></div>
    <div className={classes.packageItem}><img src={check} alt='right-tick-icon'/><span><b>3</b> Business Email</span></div>
    <div className={classes.packageItem}><img src={check} alt='right-tick-icon'/><span>1 Month <b>High-Speed</b> Hosting</span></div>
    <div className={classes.packageItem}><img src={check} alt='right-tick-icon'/><span>SSL Certificate</span></div>
    <div className={classes.packageItem}><img src={check} alt='right-tick-icon'/><span>24-Hour Malware Scanning & Optimization</span></div>
    <div className={classes.packageItem}><img src={check} alt='right-tick-icon'/><span>Broken Link Monitor</span></div>
    <div className={classes.packageItem}><img src={check} alt='right-tick-icon'/><span>24x7 Firewall Protection</span></div>
    <div className={classes.packageItem}><img src={check} alt='right-tick-icon'/><span>24/7 Uptime Monitor</span></div>
    <div className={classes.packageItem}><img src={check} alt='right-tick-icon'/><span>eCommerce Website Support</span></div>
    <div className={classes.packageItem}><img src={check} alt='right-tick-icon'/><span>Image & Media Optimization</span></div>
    <div className={classes.packageItem}><img src={check} alt='right-tick-icon'/><span>SEO Optimization</span></div>
    <div className={classes.packageItem}><img src={check} alt='right-tick-icon'/><span>Cloudflare CDN optimization</span></div>
    <div className={classes.packageItem}><img src={check} alt='right-tick-icon'/><span>Ultra-Fast Caching</span></div>
    <div className={classes.packageItem}><img src={check} alt='right-tick-icon'/><span>Enhanced Security with DDoS Protection</span></div>
    <div className={classes.packageItem}><img src={check} alt='right-tick-icon'/><span>Priority Technical Support</span></div>
    </div>
  </div>
</div>
<div className={classes.FaqCon}><MaintenanceFaq/></div>
<div className={classes.footerCon}>
      <Footer contactSection="contactSection" faqSection="faqSection" packageSection="packageSection"/>
    </div>
    
  </div>)
}

export default Maintenance;