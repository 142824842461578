import React, { useEffect, useState } from 'react';
import classes from "./styles/secondSection.module.css";
import Img from "../assets/about/s3.jpg";
const SecondSection = () => {
    const [opacity, setOpacity] = useState(0);

    const handleScroll = () => {
      const scrollTop = window.pageYOffset;
      const windowHeight = window.innerHeight;
      const element = document.querySelector(".scroll-container");
    
      // Check if the element exists
      if (!element) return;
    
      const elementTop = element.getBoundingClientRect().top + scrollTop - 100;
      const elementHeight = element.offsetHeight;
      const startFade = elementTop - windowHeight;
      const endFade = elementTop + elementHeight;
    
      if (scrollTop > startFade && scrollTop < endFade) {
        const opacityValue = (scrollTop - startFade) / (endFade - startFade);
        setOpacity(opacityValue);
      } else if (scrollTop >= endFade) {
        setOpacity(1);
      } else {
        setOpacity(0);
      }
    };
  
    useEffect(() => {
      window.addEventListener("scroll", handleScroll);
      return () => window.removeEventListener("scroll", handleScroll);
    }, []);


  return (
    <>
    <div className={`scroll-container ${classes.container}`}>
        <div className={classes.workCon}>
            <div className={`${classes.background}`} style={{ background: `rgba(255, 204, 221, ${opacity})` }}></div>
            <div className={classes.descriptionCon} >
                <div className={classes.details}>
                <p className={classes.title}>Our Purpose</p>
                <p className={classes.para}>At Wiraa, our purpose is to simplify the digital journey for businesses by offering top-notch WordPress solutions. We are dedicated to helping our clients create impactful online experiences through expert website development, seamless maintenance, tailored modifications, and effective technical SEO.<br/><br/>We aim to be the catalyst that transforms your vision into reality, enabling you to focus on what you do best while we handle the complexities of your digital presence. Our purpose is to empower your growth and success in an ever-evolving digital world.</p>
                </div>           
            </div>
            <div className={classes.workCon2}>
            <img src={Img} className={classes.Img} alt='about-us-img'/>
        </div>
        </div>  
    </div>
    </>
  );
}

export default SecondSection;