import React from 'react';
import classes from "./Styles/About.module.css";
import FirstSection from '../aboutSections/FirstSection';
import SecondSection from '../aboutSections/SecondSection';
import ThirdSection from '../aboutSections/ThirdSection';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
function About() {
  document.title = "Superworq | About";
  return <>
  <Navbar contactSection="contactSection" faqSection="faqSection" packageSection="packageSection"/>
  <div className={classes.firstCon}><FirstSection/></div>
  <div className={classes.secondCon}><SecondSection/></div>
  <div className={classes.thirdCon}><ThirdSection/></div>
  <div className={classes.footerCon}>
      <Footer contactSection="contactSection" faqSection="faqSection" packageSection="packageSection"/>
    </div>
  </>;
}

export default About;