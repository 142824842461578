import React, { useState } from 'react'
import classes from "./styles/MaintenanceFaq.module.css";
import plus from "../assets/faq/add.png";
import minus from "../assets/faq/minus.png";
const MaintenanceFaq = () => {
    const [box1 , setBox1] = useState(false);
    const [box2 , setBox2] = useState(false);
    const [box3 , setBox3] = useState(false);
    const [box4 , setBox4] = useState(false);
    const [box5 , setBox5] = useState(false);
    const [box6 , setBox6] = useState(false);

    const handlebox1 = () => {
        setBox1(!box1);
    }
    const handlebox2 = () => {
        setBox2(!box2);
    }

    const handlebox3 = () => {
        setBox3(!box3);
    }
    const handlebox4 = () => {
        setBox4(!box4);
    }
    const handlebox5 = () => {
        setBox5(!box5);
    }

    const handlebox6 = () => {
        setBox6(!box6);
    }


  return (
    <div className={classes.Container}>

        <div className={box1 ? classes.boxOpen : classes.box} onClick={handlebox1}>
            <div className={classes.upperCon}>
                <div>What services are included in your maintenance plan?</div>
                <div className={classes.logoCon}><img src={box1 ? minus : plus} alt='plus-minus-icon'/></div>
            </div>
            <div className={classes.lowerCon}>
                <div>Our maintenance plan includes daily updates for WordPress core & plugins, daily backups & bug fixes, 24/7 security monitoring, & priority support.</div>
            </div>
        </div>


        <div className={box2 ? classes.boxOpen : classes.box} onClick={handlebox2}>
            <div className={classes.upperCon}>
                <div>Is the hosting included in the maintenance plan?</div>
                <div className={classes.logoCon}><img src={box2 ? minus : plus} alt='plus-minus-icon'/></div>
            </div>
            <div className={classes.lowerCon}>
                <div>Yes, our maintenance plan includes high-speed hosting with SSL encryption to ensure your website is fast and secure.</div>
            </div>
        </div>



        <div className={box3 ? classes.boxOpen : classes.box} onClick={handlebox3}>
            <div className={classes.upperCon}>
                <div>How often are backups performed?</div>
                <div className={classes.logoCon}><img src={box3 ? minus : plus} alt='plus-minus-icon'/></div>
            </div>
            <div className={classes.lowerCon}>
                <div>We perform daily backups to ensure your data is always safe and can be restored quickly in case of any issues.</div>
            </div>
        </div>

        <div className={box4 ? classes.boxOpen : classes.box} onClick={handlebox4}>
            <div className={classes.upperCon}>
                <div>What kind of security monitoring do you provide?</div>
                <div className={classes.logoCon}><img src={box4 ? minus : plus} alt='plus-minus-icon'/></div>
            </div>
            <div className={classes.lowerCon}>
                <div>Our 24/7 security monitoring includes regular malware scans and firewall protection to keep your website secure from potential threats.</div>
            </div>
        </div>

        <div className={box5 ? classes.boxOpen : classes.box} onClick={handlebox5}>
            <div className={classes.upperCon}>
                <div>How does your SEO and media optimization work?</div>
                <div className={classes.logoCon}><img src={box5 ? minus : plus} alt='plus-minus-icon'/></div>
            </div>
            <div className={classes.lowerCon}>
                <div>We implement tailored SEO strategies to improve your search rankings and optimize media files to enhance loading speeds and user experience.</div>
            </div>
        </div>



        <div className={box6 ? classes.boxOpen : classes.box} onClick={handlebox6}>
            <div className={classes.upperCon}>
                <div>What should I do if I encounter a problem with my website?</div>
                <div className={classes.logoCon}><img src={box6 ? minus : plus} alt='plus-minus-icon'/></div>
            </div>
            <div className={classes.lowerCon}>
                <div>With our priority support, you can reach out to us anytime, and we’ll address your issues promptly to minimize downtime.</div>
            </div>
        </div>

    </div>
  )
}

export default MaintenanceFaq;
