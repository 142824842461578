import React, { useState } from 'react'
import classes from "./styles/contact.module.css";
import { addStartup } from '../Services/contact';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Contact = () => {
  const [fullName, setFullName] = useState("");
  const [country, setCountry] = useState("");
  const [email, setEmail] = useState("");
  const [mobile, setMobile] = useState("");
  const [message, setMessage] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();

    if(fullName === "" || country === "" || email === "" || mobile === "" || message === ""){
      toast.warn('All fields are mandatory!');
      return;
    }

      const data = new FormData();
      data.append("startupName", fullName);
      data.append("country", country);
      data.append("email", email);
      data.append("phoneNo" , mobile);
      data.append("description", "Superworq : " + message);
      try{
        await addStartup(data);
        setFullName("");
        setCountry("");
        setEmail("");
        setMobile("");
        setMessage("");
        console.log("Contact form filled successfully");
        toast.success('Contact form submitted successfully!');
      }catch(err){
        console.log("Enter while adding contact info :" + err);
        toast.error('Error while submitting the contact form!');
      }
    };

  return (
    <div className={classes.container}>
       <ToastContainer position="top-right" autoClose={3000} />
      <div className={classes.formCon}>
        <form className={classes.form}>
            <div className={classes.title}>Contact Us</div>
            <div className={classes.subtitle}>Drop us a line with any questions, inquiries or business proposals</div>
            
            <div className={classes.divCon}>
              <div className={classes.fieldCon}>
                <span>Name *</span>
                <input required type='text' value={fullName} placeholder="Enter your full name" onChange={(e) => setFullName(e.target.value)}/>
              </div>
              <div className={classes.fieldCon}>
                <span>Country *</span>
                <input required type='text' value={country} placeholder="Enter your country" onChange={(e) => setCountry(e.target.value)}/>
              </div>
            </div>

            <div className={classes.divCon}>
              <div className={classes.fieldCon}>
                <span>Email *</span>
                <input required type='email' value={email} placeholder="Your Email" onChange={(e) => setEmail(e.target.value)}/>
              </div>
              <div className={classes.fieldCon}>
                <span>Phone *</span>
                <input required value={mobile} placeholder="Mobile" onChange={(e) => setMobile(e.target.value)}/>
              </div>
            </div>

            <div className={classes.fieldCon}>
                <span>Leave us a message...</span>
                <textarea placeholder="Message" value={message} rows={5} onChange={(e) => setMessage(e.target.value)}/>
              </div>
            <button className={classes.btn} type="submit" onClick={handleSubmit}>Submit</button>
        </form>
      </div>
      </div>
  )
}

export default Contact;
