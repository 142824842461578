import React, { useEffect, useState } from 'react'
import classes from "./Styles/Checkout.module.css";
import logo from "../assets/Superworq.png"
import paymentLogo from "../assets/logo.png";
import { getAllPackages, paymentCheckout, paymentVerification } from '../Services/contact';
import { useLocation } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Checkout = () => {
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const color = queryParams.get('color');
    const price = queryParams.get('price');
    const plan = queryParams.get('plan');
    const time = queryParams.get('time');
    const [packages, setPackages] = useState([]);
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [email, setEmail] = useState("");
    const customerDetails = {
        name: firstName+" " +lastName,
        email: email,
        contact:'mobile',
        userid: plan,
        isVerified: 'IsVerified' ,
      };
      const amount = price;
      const currency = "USD";
      const packageId = packages[1]?.packageId;

      useEffect(() => {
        const fetchPackages = async () => {
          try {
            const data = await getAllPackages();
            setPackages(data);
          } catch (error) {
            console.log("Failed to fetch packages");
          }
        };
        fetchPackages();
      }, []);
    
    
      const paymentHandler = async (e) => {
        e.preventDefault();
        if(firstName === "" || lastName === "" || email === ""){
            toast.warn('All fields are mandatory!');
            return;
          }
        const formData = new FormData();
        formData.append("packageId", packageId);
        formData.append("amount", amount);
        formData.append("currency", currency);
    
        const response = await paymentCheckout(formData);
        const result = response.data?.r?.[0]?.order;
        const userPackageId = response.data?.r?.[0]?.PackageId;
    
    
        var options = {
          key: "rzp_live_Ja1XZ630MxbNZk",
          amount: result?.amount,
          name: "Superworq",
          description: "Buy Package",
          image: paymentLogo,
          order_id: result?.id,
          handler: async function (response) {
            const verifyData = new FormData();
            verifyData.append("firstName", customerDetails.name);
            if(customerDetails.email){
              verifyData.append("email", customerDetails.email);
            }
            verifyData.append("razorpay_payment_id", response?.razorpay_payment_id);
            verifyData.append("razorpay_order_id", response?.razorpay_order_id);
            verifyData.append("razorpay_signature", response?.razorpay_signature);
            verifyData.append("amount", result?.amount);
            verifyData.append("currency", result?.currency);
            if(customerDetails.userid){
            verifyData.append("userId", customerDetails.userid);
            }
            verifyData.append("packageId", userPackageId);
    
            try {
              const verifyres = await paymentVerification(verifyData);
              if (verifyres?.data?.message === "success") {
                  let timer;
                  timer = setTimeout(() => {
                    toast.success('Payment successful! Thank you for your purchase.');
                  }, 500);
                  if (result.isConfirmed) {
                    toast.success('Payment successful! Thank you for your purchase.');
                    clearTimeout(timer);
                  }
                }
              }catch (err) {
              console.log(err);
              toast.error('Something went wrong during the payment process. Please try again.');
            }
          },
    
          prefill: customerDetails,
          notes: {
            address: "Razorpay Corporate Office",
          },
          theme: {
            color: color,
          },
        };
        var rzp1 = new (window).Razorpay(options);
        rzp1.on("payment.failed", function (response) {
        //   alert(response.error.code);
          alert(response.error.description);
          toast.error('Payment failed. Please check your details and try again.');
        //   alert(response.error.source);
        //   alert(response.error.step);
        //   alert(response.error.reason);
        //   alert(response.error.metadata.order_id);
        //   alert(response.error.metadata.payment_id);

        });
        rzp1.open();
      };

  return (
    <div className={classes.container} style={{backgroundColor: color}}>
        <ToastContainer position="top-right" autoClose={3000} />
      <div className={classes.innerCon}>
        <div className={classes.logoCon}><img src={logo} alt='superworq-logo'/></div>

        <div className={classes.plan}>Plan</div>
        <div className={classes.packageDetails}>
        <div className={classes.packageName}>{plan}</div>
        <div className={classes.amount}>${price}{time}</div>
        </div>

        <div className={classes.infoCon}>    
                <form className={classes.form}>
                    <div className={classes.infoDiv}><span>First Name</span>
                    <input value={firstName} onChange={(e) => setFirstName(e.target.value)} placeholder='Enter first name'/>
                    </div>
                    <div className={classes.infoDiv}><span>Last Name</span>
                    <input value={lastName} onChange={(e) => setLastName(e.target.value)} placeholder='Enter last name'/>
                    </div>

                    <div className={classes.infoDiv}><span>Email</span>
                    <input value={email} onChange={(e) => setEmail(e.target.value)} placeholder='Enter email'/>
                    </div>

                    <button className={classes.btn} onClick={paymentHandler}>Complete Payment</button>
                </form>
        </div>
      </div>
    </div>
  )
}

export default Checkout;
