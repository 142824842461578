import React from 'react'
import classes from "./Styles/portfolio1.module.css";
import Img1 from "../assets/Portfolio2/1.png";
import Img2 from "../assets/Portfolio2/2.png";
import Img3 from "../assets/Portfolio2/3.png";
import Img4 from "../assets/Portfolio2/4.png";
import Img5 from "../assets/Portfolio2/5.png";
import Img6 from "../assets/Portfolio2/6.png";
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
const Portfolio2 = () => {
  return (
    <div className={classes.container}>
        <div className={classes.NavbarCon}><Navbar contactSection="contactSection" faqSection="faqSection" packageSection="packageSection"/></div>
        
       
        <div className={classes.firstCon}>
        <div className={classes.firstImgCon}><img src={Img1} alt='background-img'/></div>
     
      <div className={classes.detailCon}>
        <div className={classes.slipCon}>
            <div className={classes.nameCon}>
                <div className={classes.name}>Project:</div>
                <div className={classes.Subname}>Art Galary</div>
            </div>
            <div className={classes.nameCon}>
                <div className={classes.name}>Type:</div>
                <div className={classes.Subname}>Website</div>
            </div>
            <div className={classes.nameCon}>
                <div className={classes.name}>Deliverables:</div>
                <div className={classes.Subname}>UI Design, Development</div>
            </div>
            <div className={classes.nameCon}>
                <div className={classes.name}>Time:</div>
                <div className={classes.Subname}>1 Week</div>
            </div>
            <div className={classes.nameCon}>
                <div className={classes.name}>Package:</div>
                <div className={classes.Subname}>Starter</div>
            </div>
        </div>
       
       
        <div className={classes.line}></div>
        
        
        <div className={classes.descriptionCon}>
            <div className={classes.desTitle}>Art Galary</div>
            <div className={classes.desPara}>Art Galary is a recently launched ecommerce platform dedicated to home decoration enthusiasts. Our team developed a sleek, user-friendly website that features a wide selection of decorative items, including clocks, paintings, lamps, mirrors, table decor, and wall art. The platform emphasizes both aesthetic appeal and functionality, providing customers with an effortless shopping experience to enhance their living spaces with stylish, high-quality products.</div>
        </div>
      </div></div>


      <div className={classes.ssContainer}></div>
      <div className={classes.viewTitle}>Desktop View</div>
      <div className={classes.ssContainer1}>
        <div className={`${classes.ssCon} ${classes.ssCon1}`}><img src={Img1} className={classes.img1} alt='portfolio-screenshot'/></div>
      </div>
      <div className={classes.ssContainer1}>
        <div className={`${classes.ssCon} ${classes.ssCon1}`}><img src={Img2} className={classes.img1} alt='portfolio-screenshot'/></div>
      </div>

      <div className={classes.viewTitle}>Mobile View</div>
      <div className={classes.ssContainer2}>
      <div className={`${classes.ssCon} ${classes.ssCon2}`}><img src={Img3} className={classes.img2} alt='portfolio-screenshot'/></div>
      <div className={`${classes.ssCon} ${classes.ssCon3}`}><img src={Img4} className={classes.img2} alt='portfolio-screenshot'/></div>
      </div>

      <div className={classes.viewTitle}>Tablet View</div>
      <div className={classes.ssContainer3}>
      <div className={`${classes.ssCon} ${classes.ssCon4}`}><img src={Img5} className={classes.img2} alt='portfolio-screenshot'/></div>
      <div className={`${classes.ssCon} ${classes.ssCon5}`}><img src={Img6} className={classes.img2} alt='portfolio-screenshot'/></div>
      </div>

      <div className={classes.footerCon}>
      <Footer contactSection="contactSection" faqSection="faqSection" packageSection="packageSection"/>
    </div>
    </div>
  )
}

export default Portfolio2;
