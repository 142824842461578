import React, { useEffect, useState } from 'react';
import classes from "./styles/WhatWeDo.module.css";
import figmaImg from "../assets/whatwedo/figma.png";
import reactImg from "../assets/whatwedo/atom.png";
import wordpressImg from "../assets/whatwedo/wordpress.png";
import xdImg from "../assets/whatwedo/xd.png";
import elementorImg from "../assets/whatwedo/elementor.png";
import googleImg from "../assets/whatwedo/google.png";
import wooImg from "../assets/whatwedo/woo.png";
import jsImg from "../assets/whatwedo/js.png";
import phpImg from "../assets/whatwedo/php.png";


const WhatWeDo = () => {
    const [opacity, setOpacity] = useState(0);

    const handleScroll = () => {
        const scrollTop = window.pageYOffset;
        const windowHeight = window.innerHeight;
        const element = document.querySelector(".scroll-container");
      
        // Check if the element exists
        if (!element) return;
      
        const elementTop = element.getBoundingClientRect().top + scrollTop;
        const elementHeight = element.offsetHeight;
        const startFade = elementTop - windowHeight;
        const endFade = elementTop + elementHeight;
      
        if (scrollTop > startFade && scrollTop < endFade) {
          const opacityValue = (scrollTop - startFade) / (endFade - startFade);
          setOpacity(opacityValue);
        } else if (scrollTop >= endFade) {
          setOpacity(1);
        } else {
          setOpacity(0);
        }
      };
      

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <>
    <div className={`scroll-container ${classes.container}`} style={{ background: `rgba(255, 204, 221, ${opacity})` }}>
        
        <h1 className={classes.Title}>Build, Launch, Solve & Beyond!</h1>
        <div className={classes.lineCon}>
            <div className={classes.step}>
                <div className={classes.iconCon}><img src={xdImg} alt='xd-to-wordpress-icon'/></div>
                <p className={classes.name}>XD to WordPress</p>
            </div>
            <div className={classes.step}>
                <div className={classes.iconCon}><img src={figmaImg} alt='figma-design-icon'/></div>
                <p className={classes.name}>Figma Design</p>
            </div>
            <div className={classes.step}>
                <div className={classes.iconCon}><img src={reactImg} alt='react-development-icon'/></div>
                <p className={classes.name}>React Development</p>
            </div>
        </div>


        <div className={classes.lineCon}>
            <div className={classes.step}>
                <div className={classes.iconCon}><img src={wordpressImg} alt='wordpress-development-icon'/></div>
                <p className={classes.name}>Wordpress Development</p>
            </div>
            <div className={classes.step}>
                <div className={classes.iconCon}><img src={jsImg} alt='custom-script-icon'/></div>
                <p className={classes.name}>Custom Script</p>
            </div>
            <div className={classes.step}>
                <div className={classes.iconCon}><img src={phpImg} alt='custom-plugin-icon'/></div>
                <p className={classes.name}>Custom plugin</p>
            </div>
        </div>



        <div className={classes.lineCon}>
            <div className={classes.step}>
                <div className={classes.iconCon}><img src={wooImg} alt='woocommerce-support-icon'/></div>
                <p className={classes.name}>WooCommerce support</p>
            </div>
            <div className={classes.step}>
                <div className={classes.iconCon}><img src={elementorImg} alt='page-management-icon'/></div>
                <p className={classes.name}>Page Management</p>
            </div>
            <div className={classes.step}>
                <div className={classes.iconCon}><img src={googleImg} alt='google-search-console-icon'/></div>
                <p className={classes.name}>Google Search Console</p>
            </div>
        </div>
        
      </div>
    </>
  );
}

export default WhatWeDo;