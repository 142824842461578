import React, { useEffect, useState } from 'react';
import classes from "./Styles/seo.module.css";
import Navbar from '../components/Navbar';
import Img1 from "../assets/seo/2.png";
import Img2 from "../assets/seo/1.png";

import meta from "../assets/seo/meta.png";
import google from "../assets/seo/google.png";
import search from "../assets/seo/search.png";
import content from "../assets/seo/content.png";
import tag from "../assets/seo/tag.png";
import header from "../assets/seo/header.png";

import check from "../assets/check.svg";
import Footer from '../components/Footer';
import SeoFaq from '../aboutSections/SeoFaq';

function Seo() {
  const [opacity, setOpacity] = useState(0);

  const handleButtonClick1 = () => {
    const queryParams = new URLSearchParams({
      color: '#FFCCDD',
      price: "249",
      plan: 'RankBoost',
      time: ''
    }).toString();
    window.open(`/#/checkout?${queryParams}`, '_blank');
  };


  useEffect(() => {
    document.title = "Wordpress Website SEO";
    const metaDescription = document.querySelector('meta[name="description"]');
    if (metaDescription) {
      metaDescription.setAttribute('content', 'Comprehensive WordPress Search engine optimization Services for Enhanced Visibility.');
    } else {
      const newMetaDescription = document.createElement('meta');
      newMetaDescription.name = 'description';
      newMetaDescription.content = 'Comprehensive WordPress Search engine optimization Services for Enhanced Visibility.';
      document.getElementsByTagName('head')[0].appendChild(newMetaDescription);
    }
  }, []);


  const handleScroll = () => {
    const scrollTop = window.pageYOffset;
    const windowHeight = window.innerHeight;
    const element = document.querySelector(".scroll-container");
  
    // Check if the element exists
    if (!element) return;
  
    const elementTop = element.getBoundingClientRect().top + scrollTop - 100;
    const elementHeight = element.offsetHeight;
    const startFade = elementTop - windowHeight;
    const endFade = elementTop + elementHeight;
  
    if (scrollTop > startFade && scrollTop < endFade) {
      const opacityValue = (scrollTop - startFade) / (endFade - startFade);
      setOpacity(opacityValue);
    } else if (scrollTop >= endFade) {
      setOpacity(1);
    } else {
      setOpacity(0);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const scrollToPackage = () => {
    const element = document.getElementById('priceSection');
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (

  <div className={classes.container}>
  <Navbar contactSection="contactSection" faqSection="faqSection" packageSection="packageSection"/>
  <div className={classes.background}><canvas id="test"></canvas></div>
  <div className={classes.firstDiv}>
    <div className={classes.heading}>Expert Optimization for Your Success</div>
    <div className={classes.title}>Comprehensive WordPress SEO Services for Enhanced Visibility</div>
    <div className={classes.subTitle}>Boost your WordPress site's search engine rankings with our expert SEO services. We perform in-depth keyword research, on-page optimization, and technical SEO to enhance your site’s visibility and drive organic traffic. Let us handle the intricacies of SEO so you can focus on growing your business.</div>
    <div className={classes.btnDiv}><button className={classes.contactBtn} onClick={scrollToPackage}>See Plans</button></div>
  </div>


  {/* -----------------------What is website maintenance section ------------------------ */}

  <div className={classes.vector1Con}>
    <div className={classes.vector1left}>
      <img src={Img1} alt='vector'/>
    </div>
    <div className={classes.vector1right}>
      <div className={classes.question}>What is technical SEO and why is it important?</div>
      <div className={classes.ans}>Technical SEO focuses on optimizing the technical aspects of your website to improve its crawlability and indexability by search engines. This includes enhancing site speed, ensuring mobile compatibility, optimizing site structure, and fixing crawl errors. Proper technical SEO is crucial for ensuring that search engines can effectively access and understand your site’s content.</div>
    </div>
  </div>

  {/* -----------------------Why is  website maintenance important section ------------------------ */}


  <div className={classes.vector2Con}>
    <div className={classes.vector2left}>
      <div className={classes.question}>How can SEO modifications benefit my website?</div>
      <div className={classes.ans}>SEO modifications can enhance your website’s search engine rankings, drive more organic traffic, and improve user experience. By optimizing content, improving site structure, and addressing technical issues, you can attract more relevant visitors, increase engagement, and achieve better conversion rates.</div>
    </div>
    <div className={classes.vector2right}>
      <img src={Img2} alt='vector'/>
    </div>
  </div>

  
  {/* -----------------------Benfits container section ------------------------ */}

  <div className={`scroll-container ${classes.featureCon}`}>
    <div className={classes.featureInnerCon} style={{ background: `rgba(136, 221, 255, ${opacity})` }}>
      <div className={classes.featureTitle}>Essential Elements of a WordPress Modification Package</div>



      <div className={classes.featureDivLine}>
  <div className={classes.featureDiv}>
    <div className={classes.featureLogo}><img src={google} alt='google-analytics-icon'/></div>
    <div className={classes.featureName}>Google Analytics</div>
    <div className={classes.featurePara}>Track and analyze your website’s performance with precise data insights</div>
  </div>

  <div className={classes.featureDiv}>
    <div className={classes.featureLogo}><img src={search} alt='google-search-console-icon'/></div>
    <div className={classes.featureName}>Google Search Console</div>
    <div className={classes.featurePara}>Monitor your site’s presence in Google Search & fix issues for better indexing</div>
  </div>

  <div className={classes.featureDiv}>
    <div className={classes.featureLogo}><img src={tag} alt='google-tag-manager-icon'/></div>
    <div className={classes.featureName}>Google Tag Manager</div>
    <div className={classes.featurePara}>Simplify tracking and tag management with our seamless implementation</div>
  </div>
</div>

<div className={classes.featureDivLine}>
  <div className={classes.featureDiv}>
    <div className={classes.featureLogo}><img src={meta} alt='meta-tag-optimization-icon'/></div>
    <div className={classes.featureName}>Meta Tags Optimization</div>
    <div className={classes.featurePara}>Enhance your site’s relevance with optimized title & description tags</div>
  </div>

  <div className={classes.featureDiv}>
    <div className={classes.featureLogo}><img src={header} alt='header-tags-optimization-icon'/></div>
    <div className={classes.featureName}>Header Tags Optimization</div>
    <div className={classes.featurePara}>Organize content structure & improve SEO with well-optimized header tags</div>
  </div>

  <div className={classes.featureDiv}>
    <div className={classes.featureLogo}><img src={content} alt='content-optimization-icon'/></div>
    <div className={classes.featureName}>Content Optimization</div>
    <div className={classes.featurePara}>Improve readability & SEO with strategically crafted, high-quality content</div>
  </div>

</div>


</div>
</div>


{/* -----------------------Packages section ------------------------ */}

<div className={`${classes.packageCon} `} >
<div className={classes.packageShape}><span>Premium</span></div>
  <div className={classes.packageLeftCon}>
    <div className={classes.packageleftTitle}>Ultimate SEO Optimization Package</div>
    <div className={classes.packageleftSubtitle}>Boost Your Rankings with Expert SEO Strategies</div>
    <div className={classes.packageleftPara}>Unlock your website's full potential with our all-in-one SEO solution.
    This package includes essential services to boost your online presence and improve search engine rankings<br/>Ensure your site is fully optimized and performance-ready.
    No commitment, use our services as needed, with the flexibility to pause and resume whenever you like.</div>
  </div>


  <div className={`${classes.packageDiv} ${classes.p2}`} id='priceSection'>
    <div className={classes.upperPackage}>
    <div className={classes.packageName}>RankBoost</div>
    <div className={classes.packagePrice}>$249</div>
    <div className={classes.packageSubtitle}>All-In-One SEO Package</div>
    <div className={classes.packageBtnCon}><button className={classes.packageBtn} onClick={handleButtonClick1}>Get Started</button></div>
    </div>
    <div className={classes.lowerPackage}>
    <div className={classes.packageItem}><img src={check} alt='right-tick-icon'/><span>Google Analytics & Search Console Setup</span></div>
    <div className={classes.packageItem}><img src={check} alt='right-tick-icon'/><span>Google Tag Manager Installation</span></div>
    <div className={classes.packageItem}><img src={check} alt='right-tick-icon'/><span>Meta Tags & Header Tags Optimization</span></div>
    <div className={classes.packageItem}><img src={check} alt='right-tick-icon'/><span>Content Optimization</span></div>
    <div className={classes.packageItem}><img src={check} alt='right-tick-icon'/><span>Site Speed & Mobile Optimization</span></div>
    <div className={classes.packageItem}><img src={check} alt='right-tick-icon'/><span>XML Sitemap & Alt Text Implementation</span></div>
    </div>
  </div>
</div>
<div className={classes.FaqCon}><SeoFaq/></div>

<div className={classes.footerCon}>
      <Footer contactSection="contactSection" faqSection="faqSection" packageSection="packageSection"/>
    </div>
    
  </div>)
}

export default Seo;