import React, { useEffect, useState } from 'react';
import classes from "./styles/firstSection.module.css";
import Img from "../assets/about/f1.jpg";
const FirstSection = () => {
    const [opacity, setOpacity] = useState(0);

    const handleScroll = () => {
      const scrollTop = window.pageYOffset;
      const windowHeight = window.innerHeight;
      const element = document.querySelector(".scroll-container");
    
      // Check if the element exists
      if (!element) return;
    
      const elementTop = element.getBoundingClientRect().top + scrollTop - 200;
      const elementHeight = element.offsetHeight;
      const startFade = elementTop - windowHeight;
      const endFade = elementTop + elementHeight;
    
      if (scrollTop > startFade && scrollTop < endFade) {
        const opacityValue = (scrollTop - startFade) / (endFade - startFade);
        setOpacity(opacityValue);
      } else if (scrollTop >= endFade) {
        setOpacity(1);
      } else {
        setOpacity(0);
      }
    };
  
    useEffect(() => {
      window.addEventListener("scroll", handleScroll);
      return () => window.removeEventListener("scroll", handleScroll);
    }, []);


  return (
    <>
    <div className={classes.container}>
        <div className={classes.workCon}>
            <div className={`scroll-container ${classes.background}`} style={{ backgroundColor: `rgba(136, 221, 255, ${opacity === 0  ? 0.5 : opacity})` }}></div>
            <div className={classes.descriptionCon} >
                <div className={classes.details}>
                <p className={classes.title}>Our Story</p>
                <p className={classes.para}>Welcome to Wiraa, your trusted partner in WordPress website development, maintenance, modifications, and technical SEO. We started with a simple vision: to empower businesses with beautifully crafted, high-performing websites that reflect their unique identity. Whether you’re launching a new site or enhancing an existing one, our team is dedicated to providing solutions tailored to your specific needs.<br/><br/>At Wiraa, we believe in building long-lasting relationships with our clients through transparency, trust, and excellence in everything we do. We’re more than just a service provider; we’re here to help you succeed and grow in the digital world. Let’s create something extraordinary together!</p>
                </div>           
            </div>
        </div>

        <div className={classes.workCon2}>
        <img src={Img} className={classes.Img} alt='about-us-img'/>
        </div>
        
        
    </div>
    </>
  );
}

export default FirstSection;