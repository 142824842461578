import React, { useEffect, useState } from 'react';
import classes from "./styles/AdditionalService.module.css";
import option from "../assets/service/option.png";
import modifications from "../assets/service/devops.png";
import maintenance from "../assets/service/maintenance.png"
import seo from "../assets/service/seo2.png";
import { useNavigate } from 'react-router-dom';


function AdditionalServices() {
    const navigate = useNavigate();
    const [opacity, setOpacity] = useState(0);
    const handleModification = () => {
        navigate("/wordpress-website-modification");
    }

    const handleMaintenance = () => {
        navigate("/wordpress-website-maintenance");
    }

    const handleSeo = () => {
        navigate("/wordpress-website-seo");
    }

    const handleScroll = () => {
        const scrollTop = window.pageYOffset;
        const windowHeight = window.innerHeight;
        const element = document.querySelector(".scroll-container");
      
        // Check if the element exists
        if (!element) return;
      
        const elementTop = 5000;
        const elementHeight = element.offsetHeight;
        const startFade = elementTop - windowHeight;
        const endFade = elementTop + elementHeight;
      
        if (scrollTop > startFade && scrollTop < endFade) {
          const opacityValue = (scrollTop - startFade) / (endFade - startFade);
          setOpacity(opacityValue);
        } else if (scrollTop >= endFade) {
          setOpacity(1);
        } else {
          setOpacity(0);
        }
      };

      useEffect(() => {
        window.addEventListener("scroll", handleScroll);
        return () => window.removeEventListener("scroll", handleScroll);
      }, []);
    
  return (
    <>
    <div className={`scroll-container ${classes.container}`} style={{ background: `rgba(255, 238, 204, ${opacity})` }}>
        <div className={classes.Title}>Boost your online presence with our extra services</div>
        <div className={classes.subTitle}>Keep your website running smoothly with our services that include ongoing maintenance, smooth modifications, and powerful SEO to ensure a high-performing and visible online presence.</div>
        <div className={classes.mainCon}>


            <div className={classes.con}>
            <div className={classes.upper}><img src={option} alt='option-icon'/></div>
                <div className={classes.logoCon}>
                    <img src={maintenance} alt='maintenance-icon'/>
                </div>
                <div className={classes.heading}>We Maintain</div>
                <div className={classes.para}>Ensure smooth operation with updates, security patches, and performance checks.</div>
                <div className={classes.btnCon}><button className={classes.btn} onClick={handleMaintenance}>See More</button></div>
            </div>

            <div className={classes.con}>
            <div className={classes.upper}><img src={option} alt='option-icon'/></div>
                <div className={classes.logoCon}>
                    <img src={modifications} alt='modification-icon'/>
                </div>
                <div className={classes.heading}>We Modify</div>
                <div className={classes.para}>Upgrade functionality with custom changes to meet your evolving business needs.</div>
                <div className={classes.btnCon}><button className={classes.btn} onClick={handleModification}>See More</button></div>
            </div>

            <div className={classes.con}>
                <div className={classes.upper}><img src={option} alt='option-icon'/></div>
                <div className={classes.logoCon}>
                    <img src={seo} alt='seo-icon'/>
                </div>
                <div className={classes.heading}>We Optimize</div>
                <div className={classes.para}>Increase visibility with optimized strategies to improve rankings and attract targeted traffic.</div>
                <div className={classes.btnCon}><button className={classes.btn} onClick={handleSeo}>See More</button></div>
            </div>
        </div>
      </div>
    </>
  );
}

export default AdditionalServices;