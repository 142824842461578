import axios from 'axios';

export const addStartup = async (startupData) => {
    try {
      const response = await axios.post(`https://wiraatest.azurewebsites.net/api/v1/startup/addStartup`, startupData);
      return response.data;
    } catch (error) {
      console.error('Error adding startup:', error);
      throw error;
    }
  };

  export const paymentCheckout = async (formData)=> {
    try {
      const response = await axios.post(`https://wiraatest.azurewebsites.net/api/v1/payment/paymentCheckout`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      return response;
    } catch (error) {
      console.error("Error processing payment checkout:", error);
      throw error;
    }
  };


  export const paymentVerification = async (formData)=> {
    try {
      const response = await axios.post(`https://wiraatest.azurewebsites.net/api/v1/payment/paymentVerification`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      return response;
    } catch (error) {
      console.error("Error processing payment checkout:", error);
      throw error;
    }
  };

  export const getAllPackages = async () => {
    try {
      const response = await axios.get(`https://wiraatest.azurewebsites.net/api/v1/package/getAllPackage`);
      return response.data;
    } catch (error) {
      console.error("Error fetching packages:", error);
      throw error;
    }
  };