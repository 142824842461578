import React, { useEffect, useState} from 'react';
import classes from "./styles/HowWeWork.module.css";
import planningImg from "../assets/planning.png";
import testingImg from "../assets/testing.png";
import webDesignImg from "../assets/web-design.png";
import codingImg from "../assets/coding.png";
import contentCreationImg from "../assets/content-creator.png";
import launchImg from "../assets/launch.png";

function HowWeWork() {

    const [scrolled, setScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY || document.documentElement.scrollTop;

      if (scrollPosition >= 300 && !scrolled) {
        setScrolled(true);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [scrolled]);
  
  return (
    <div className={classes.container}>
        <div className={`${classes.startPoint} ${scrolled ? classes.aniStartPoint : ''}`}>
          <img src={planningImg} className={classes.Img} alt='planning-icon'/>
          <p>Planning</p>
        </div>

        <div className={`${classes.strightLine} ${scrolled ? classes.startLine : ''}`}></div>

        <div className={`${classes.startPoint} ${classes.endPoint} ${scrolled ? classes.aniEndPoint : ''}`}>
          <img src={launchImg} className={classes.Img} alt='launch-icon'/>
          <p>Launch</p>
        </div>

        <div className={`${classes.startPoint} ${classes.designPoint} ${scrolled ? classes.aniDesignPoint : ''}`}>
          <img src={webDesignImg} className={classes.Img} alt='design-icon'/>
          <p>Design</p>
        </div>

        <div className={`${classes.startPoint} ${classes.developmentPoint} ${scrolled ? classes.aniDevelopmentPoint : ''}`}>
          <img src={codingImg} className={classes.Img} alt='develop-icon'/>
          <p>Develop</p>
        </div>

        <div className={`${classes.startPoint} ${classes.contentPoint} ${scrolled ? classes.aniContentPoint : ''}`}>
          <img src={contentCreationImg} className={classes.Img} alt='content-icon'/>
          <p>Content</p>
        </div>

        <div className={`${classes.startPoint} ${classes.testingPoint} ${scrolled ? classes.aniTestingPoint : ''}`}>
          <img src={testingImg} className={classes.Img} alt='testing-icon'/>
          <p>Testing</p>
        </div>



        <div className={`${classes.planningLine} ${scrolled ? classes.startPlanningLine : ''}`}></div>
        <div className={`${classes.designLine} ${scrolled ? classes.startDesignLine : ''}`}></div>
        <div className={`${classes.developLine} ${scrolled ? classes.startDevelopLine : ''}`}></div>
        <div className={`${classes.testingLine} ${scrolled ? classes.startTestingLine : ''}`}></div>
      </div>
  );
}

export default HowWeWork;