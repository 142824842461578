import './App.css';
import {HashRouter, Route, Routes } from 'react-router-dom';
import About from './pages/About';
import Modifications from './pages/Modifications';
import Maintenance from './pages/Maintenance';
import Home from './pages/Home';
import ProjectForm from './pages/ProjectForm';
import Seo from './pages/Seo';
import Portfolio1 from './pages/Portfolio1';
import Checkout from './pages/Checkout';
import Portfolio2 from './pages/Portfolio2';
function App() {
  return (
    <>
      <HashRouter>
        <Routes>
        <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/wordpress-website-maintenance" element={<Maintenance />} />
          <Route path="/wordpress-website-modification" element={<Modifications/>}/>
          <Route path="/wordpress-website-seo" element={<Seo/>}/>
          <Route path="/project-form" element={<ProjectForm/>} />
          <Route path="/recent-work/1" element={<Portfolio1/>} />
          <Route path="/recent-work/2" element={<Portfolio2/>} />
          <Route path="/checkout" element={<Checkout/>} />
          <Route path="*" element={<h1>404 - Page Not Found</h1>} />
        </Routes>
      </HashRouter>
    </>
  );
}

export default App;



