import React, { useEffect, useState } from 'react';
import classes from "./styles/RecentWork.module.css";
import ss1Img from "../assets/work/ss3.png";
import ss2Img from "../assets/Portfolio2/1.png";

const RecentWork = () => {
    const [opacity, setOpacity] = useState(0);

    const handleScroll = () => {
        const scrollTop = window.pageYOffset;
        const windowHeight = window.innerHeight;
        const element = document.querySelector(".scroll-container");
      
        // Check if the element exists
        if (!element) return;
      
        const elementTop = 2300;
        const elementHeight = element.offsetHeight;
        const startFade = elementTop - windowHeight;
        const endFade = elementTop + elementHeight;
      
        if (scrollTop > startFade && scrollTop < endFade) {
          const opacityValue = (scrollTop - startFade) / (endFade - startFade);
          setOpacity(opacityValue);
        } else if (scrollTop >= endFade) {
          setOpacity(1);
        } else {
          setOpacity(0);
        }
      };

      useEffect(() => {
        window.addEventListener("scroll", handleScroll);
        return () => window.removeEventListener("scroll", handleScroll);
      }, []);


  return (
    <>
    <div className={classes.container}>
        <div className={classes.workCon}>
            <div className={`scroll-container ${classes.background}`} style={{ backgroundColor: `rgba(255, 238, 204, ${opacity})` }}></div>
            <div className={classes.descriptionCon} >
                <div className={classes.details}>
                <p className={classes.title}>Our Latest Projects</p>
                <p className={classes.subtitle}>Discover what we’ve been up to</p>
                <p className={classes.para}>Check out our recent WordPress projects, showcasing the websites we've built for clients across different industries. Each project reflects our dedication to delivering high-quality, user-friendly websites. Browse through to see the work we've done and the impact it's making.</p>
                </div>           
            </div>
        </div>

        <div className={classes.workCon2}>
            <a href='#/recent-work/1' target='_blank' className={classes.link}><img src={ss1Img} alt='recentwork' className={classes.Img}/></a>
        </div>
        <div className={classes.workCon3}>
        <a href='#/recent-work/2' target='_blank' className={classes.link}><img src={ss2Img} className={classes.Img} alt='recentwork'/></a>
        </div>
        
        
    </div>
    </>
  );
}

export default RecentWork;