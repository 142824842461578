import React from 'react';
import classes from "./styles/Packages.module.css";
import check from "../assets/check.svg";

function Packages() {
  const handleButtonClick1 = () => {
    const queryParams = new URLSearchParams({
      color: '#EFEFEF',
      price: "499",
      plan: 'Starter',
      time: ''
    }).toString();
    window.open(`/#/checkout?${queryParams}`, '_blank');
  };

  const handleButtonClick2 = () => {
    const queryParams = new URLSearchParams({
      color: '#EFEFEF',
      price: "999",
      plan: 'Medium',
      time: ''
    }).toString();
    window.open(`/#/checkout?${queryParams}`, '_blank');
  };

  const handleButtonClick3 = () => {
    const queryParams = new URLSearchParams({
      color: '#FFCCDD',
      price: "1999",
      plan: 'Enterprise',
      time: ''
    }).toString();
    window.open(`/#/checkout?${queryParams}`, '_blank');
  };
    
    const list1 = [
        "Up to 5 Pages",
        "Basic SEO setup for search engine visibility",
        "SSL Certificate & Basic Hosting",
        "Domain-Based Email Setup",
        "Google Analytics setup",
        "Mobile-responsive design",
        "Blog setup & Contact form integration",
        "Social Media Integration",
        "3 revisions Included",
        "30 Days of Updates & Technical Support",
    ]

    const list2 = [
        "Up to 10 Pages",
        "On-page SEO for better search engine ranking",
        "SSL & High-Speed Hosting",
        "Domain-Based Email Setup",
        "Google Analytics & Search Console setup",
        "Mobile-responsive design with additional Customization",
        "Blog Setup, Contact Forms, & Lead Capture Forms",
        "Social Media Integration & Feed Display",
        "5 revisions Included",
        "30 Days of Updates & Technical Support",
        "E-Commerce Setup with Payment Gateway Integration"
    ]

    const list3 = [
        "Unlimited Pages",
        "Advanced SEO strategies and keyword research",
        "SSL & High-Speed Hosting",
        "Domain-Based Email Setup",
        "Google Analytics, Google Tag Manager, & Search Console setup",
        "Mobile-responsive design with advanced Customizations",
        "Blog Setup, Custom-Designed Contact Forms & Lead Generation Tools",
        "Social Media Integration & Marketing Tool Setup",
        "Unlimited Revisions Included",
        "30 Days of Updates, Technical Support, & Priority Assistance",
        "Full E-Commerce Functionality with Advanced Payment Options"
    ]
  return (
    <>
    <div className={classes.container}>
        <div className={classes.con}>
            <div className={classes.upperCon}>
                <h5 className={classes.packageName}>Starter</h5>
                <p className={classes.price}>$499</p>
                <p className={classes.subtitle}>Perfect for startups or businesses with a simple and minimalist brochure website.</p>
                <div className={classes.btnCon}><button className={classes.btn} onClick={handleButtonClick1}>Get Started</button></div>
            </div>
            <div className={`${classes.lowerCon} ${classes.lc1}`}>
                {list1.map((item, i) => {
                    return <div className={classes.item} key={i}><img src={check} alt='right-tick-icon'/><span>{item}</span></div>
                })}
            </div>
        </div>
        <div className={classes.con}>
            <div className={classes.upperCon}>
                <h5 className={classes.packageName}>Medium</h5>
                <p className={classes.price}>$999</p>
                <p className={classes.subtitle}>Ideal for business that are already established and looking to go that next level.</p>
                <div className={classes.btnCon}><button className={classes.btn} onClick={handleButtonClick2}>Get Started</button></div>
            </div>
            <div className={`${classes.lowerCon} ${classes.lc2}`}>
                {list2.map((item, i) => {
                    return <div className={classes.item} key={i}><img src={check} alt='right-tick-icon'/><span>{item}</span></div>
                })}
            </div>
            
        </div>
        <div className={classes.con}>
            <div className={classes.upperCon2}>
                <h5 className={classes.packageName2}>Enterprise</h5>
                <p className={classes.price2}>$1999+</p>
                <p className={classes.subtitle2}>For established businesses looking to dominate their competitive market landscape.</p>
                <div className={classes.btnCon}><button className={classes.btn2} onClick={handleButtonClick3}>Get Started</button></div>
            </div>
            <div className={`${classes.lowerCon} ${classes.lc3}`}>
                {list3.map((item, i) => {
                    return <div className={classes.item} key={i}><img src={check} alt='right-tick-icon'/><span>{item}</span></div>
                })}
            </div>
            
        </div>
    </div>
    </>
  );
}

export default Packages;