import React, { useState } from 'react'
import classes from "./styles/ModificationFaq.module.css";
import plus from "../assets/faq/add.png";
import minus from "../assets/faq/minus.png";
const ModificationFaq = () => {
    const [box1 , setBox1] = useState(false);
    const [box2 , setBox2] = useState(false);
    const [box3 , setBox3] = useState(false);
    const [box4 , setBox4] = useState(false);
    const [box5 , setBox5] = useState(false);
    const [box6 , setBox6] = useState(false);

    const handlebox1 = () => {
        setBox1(!box1);
    }
    const handlebox2 = () => {
        setBox2(!box2);
    }

    const handlebox3 = () => {
        setBox3(!box3);
    }
    const handlebox4 = () => {
        setBox4(!box4);
    }
    const handlebox5 = () => {
        setBox5(!box5);
    }

    const handlebox6 = () => {
        setBox6(!box6);
    }


  return (
    <div className={classes.Container}>

        <div className={box1 ? classes.boxOpen : classes.box} onClick={handlebox1}>
            <div className={classes.upperCon}>
                <div>Can you work with my custom plugin and theme?</div>
                <div className={classes.logoCon}><img src={box1 ? minus : plus} alt='plus-minus-icon'/></div>
            </div>
            <div className={classes.lowerCon}>
                <div>We specialize in modifying and optimizing both custom plugins and themes. Whether you need enhancements, bug fixes, or new features, we can work with your existing setup to ensure it meets your specific requirements.</div>
            </div>
        </div>


        <div className={box2 ? classes.boxOpen : classes.box} onClick={handlebox2}>
            <div className={classes.upperCon}>
                <div>What plugins and page builders do you support?</div>
                <div className={classes.logoCon}><img src={box2 ? minus : plus} alt='plus-minus-icon'/></div>
            </div>
            <div className={classes.lowerCon}>
                <div>We support a wide range of popular plugins and page builders, including Elementor, WPBakery, WooCommerce, and many more. If you have specific plugins or tools in mind, feel free to ask, and we can confirm compatibility.</div>
            </div>
        </div>



        <div className={box3 ? classes.boxOpen : classes.box} onClick={handlebox3}>
            <div className={classes.upperCon}>
                <div>How fast can changes be implemented?</div>
                <div className={classes.logoCon}><img src={box3 ? minus : plus} alt='plus-minus-icon'/></div>
            </div>
            <div className={classes.lowerCon}>
                <div>Our typical turnaround time depends on the complexity of the changes, but we strive to deliver updates quickly without compromising quality.</div>
            </div>
        </div>

        <div className={box4 ? classes.boxOpen : classes.box} onClick={handlebox4}>
            <div className={classes.upperCon}>
                <div>Do you also integrate third-party tools?</div>
                <div className={classes.logoCon}><img src={box4 ? minus : plus} alt='plus-minus-icon'/></div>
            </div>
            <div className={classes.lowerCon}>
                <div>Yes, we specialize in integrating services like payment gateways, CRM systems, and marketing tools to enhance your site’s functionality.</div>
            </div>
        </div>

        <div className={box5 ? classes.boxOpen : classes.box} onClick={handlebox5}>
            <div className={classes.upperCon}>
                <div>Do you offer ongoing support after modifications?</div>
                <div className={classes.logoCon}><img src={box5 ? minus : plus} alt='plus-minus-icon'/></div>
            </div>
            <div className={classes.lowerCon}>
                <div>Yes, we provide ongoing support and maintenance to ensure your website continues running smoothly post-modifications.</div>
            </div>
        </div>



        <div className={box6 ? classes.boxOpen : classes.box} onClick={handlebox6}>
            <div className={classes.upperCon}>
                <div>How do you handle SEO during modifications?</div>
                <div className={classes.logoCon}><img src={box6 ? minus : plus} alt='plus-minus-icon'/></div>
            </div>
            <div className={classes.lowerCon}>
                <div>We ensure that any changes made are SEO-friendly, maintaining or improving your site’s search engine rankings.</div>
            </div>
        </div>

    </div>
  )
}

export default ModificationFaq;
