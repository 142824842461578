import React, { useEffect, useRef } from 'react';
import classes from "./Styles/home.module.css";
import Navbar from '../components/Navbar';
import Industry from '../sections/Industry';
import HowWeWork from '../sections/HowWeWork';
import RecentWork from '../sections/RecentWork';
import WhatWeDo from '../sections/WhatWeDo';
import Testimonial from '../sections/Testimonial';
import Packages from '../sections/Packages';
import AdditionalServices from '../sections/AdditionalServices';
import FAQ from '../sections/FAQ';
import Footer from '../components/Footer';
import Contact from '../sections/Contact';
import "./Styles/home.css";

import shape8 from "../assets/shapes2/15.png";
import shape9 from "../assets/shapes/9.png";
import shape6 from "../assets/shapes/6.png";
import shape12 from "../assets/shapes/12.png";
import shape14 from "../assets/shapes2/28.png";

function Home() {
  const contactSectionRef = useRef(null);
  useEffect(() => {
    const delayImport = () => {
      // import('./canvas');
    };

    const timeoutId = setTimeout(() => {
      delayImport();
    }, 300);

    return () => clearTimeout(timeoutId);
  }, []);

  const scrollToContact = () => {
    const element = document.getElementById("contactSection");
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  }
  return (
    <>
    <div className={classes.background3}>
    <section>
  <div className='air air1'></div>
  <div className='air air2'></div>
  <div className='air air3'></div>
  <div className='air air4'></div>
</section></div>
    <div className={classes.firstDiv}>
      <div className={classes.firstInnerDiv}>
    <Navbar contactSection="contactSection" faqSection="faqSection" packageSection="packageSection"/>
    <div className={classes.heading}>Simplify Your Online Presence</div>
    <h1 className={classes.title}>We create and manage Wordpress websites exactly the way you need it</h1>
    <h5 className={classes.subTitle}>Efficient website development including 24/7 support and maintenance, saving up to 90% on development costs and avoiding hiring headaches.</h5>
    <div className={classes.btnDiv}><button className={classes.contactBtn} onClick={scrollToContact}>Contact Now</button></div>
    {/* <div className={classes.desktopGifDiv}><img src={DesktopGif} className={classes.desktopGif}/></div> */}
    </div></div>
    <div className={classes.howweworkTitle}>How We Work</div>
    <div className={classes.secondDiv}>
      <HowWeWork/>
    </div>

    <div className={classes.thirdDiv}>
    <div className={classes.industyShape}><img src={shape12} alt='shape'/></div>
      <Industry/>
    </div>

    <div className={classes.whatweDoDiv}>
    <div className={classes.whatweDoShape}><img src={shape14} alt='shape'/></div>
      <WhatWeDo/>
    </div>

    {/* <div className={classes.recentworkTitle}>Recent Work</div> */}
    <div className={classes.recentWorkDiv}>
    <div className={classes.recentworkShape2}><img src={shape6} alt='shape'/></div>
      <RecentWork/>
    </div>
    <div className={classes.testimonialDiv}>
    <div className={classes.testimonialShape}><img src={shape9} alt='shape'/></div>
    <Testimonial/>
    </div>

    <div className={classes.packagesDiv} id='packageSection'>
    <div className={classes.packageShape}><span>Premium</span></div>
    <Packages/>
    </div>
    <div className={classes.adtionalServiceDiv}>
    <div className={classes.additionalShape}><img src={shape8} alt='shape'/></div>
    <AdditionalServices/>
    </div>
    <div className={classes.faqDiv} id='faqSection' ref={contactSectionRef}>
    <FAQ/>
    </div>
    <div className={classes.contactCon} id='contactSection' ref={contactSectionRef}>
      <Contact/>
    </div>
    <div className={classes.footerCon}>
      <Footer contactSection="contactSection" faqSection="faqSection" packageSection="packageSection"/>
    </div>
  
  </>
);
}

export default Home;
