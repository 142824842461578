import React from 'react';
import classes from "./styles/Industry.module.css";

function Industry() {
  const list= [
    { name: "Fintech", color: "rgba(255, 182, 193, 0.8)" },
    { name: "ECommerce", color: "rgba(62, 156, 245, 0.9)" }, 
    { name: "Healthcare", color: "rgba(255,255,0,0.8)" },    
    { name: "Fitness", color: "rgba(255, 182, 193, 0.8)" }, 
    { name: "Food Delivery", color: "rgba(62, 156, 245, 0.9)" },
    { name: "Real Estate", color: "rgba(255,255,0,0.8)" },
    { name: "Travel", color: "rgba(255, 182, 193, 0.8)" },
    { name: "Sports", color: "rgba(62, 156, 245, 0.9)" },
    { name: "Social Networking", color: "rgba(255,255,0,0.8)" },
    { name: "Business", color: "rgba(255, 182, 193, 0.8)" },
    { name: "Education", color: "rgba(62, 156, 245, 0.9)" },
    { name: "Entertainment", color: "rgba(255,255,0,0.8)" },
    { name: "Dating", color: "rgba(255, 182, 193, 0.8)" },
    { name: "Career", color: "rgba(62, 156, 245, 0.9)" },
    { name: "Transport", color: "rgba(255,255,0,0.8)" },
    { name: "Grocery", color: "rgba(255, 182, 193, 0.8)" },
    // Repeat the pattern
    { name: "Fintech", color: "rgba(62, 156, 245, 0.9)" },
    { name: "ECommerce", color: "rgba(255,255,0,0.8)" },
    { name: "Healthcare", color: "rgba(255, 182, 193, 0.8)" },
    { name: "Fitness", color: "rgba(62, 156, 245, 0.9)" },
    { name: "Food Delivery", color: "rgba(255,255,0,0.8)" },
    { name: "Real Estate", color: "rgba(255, 182, 193, 0.8)" },
    { name: "Travel", color: "rgba(62, 156, 245, 0.9)" },
    { name: "Sports", color: "rgba(255,255,0,0.8)" },
    { name: "Social Networking", color: "rgba(255, 182, 193, 0.8)" },
    { name: "Business", color: "rgba(62, 156, 245, 0.9)" },
    { name: "Education", color: "rgba(255,255,0,0.8)" },
    { name: "Entertainment", color: "rgba(255, 182, 193, 0.8)" },
    { name: "Dating", color: "rgba(62, 156, 245, 0.9)" },
    { name: "Career", color: "rgba(255,255,0,0.8)" },
    { name: "Transport", color: "rgba(255, 182, 193, 0.8)" },
    { name: "Grocery", color: "rgba(62, 156, 245, 0.9)" },

    { name: "Fintech", color: "rgba(255, 182, 193, 0.8)" },
    { name: "ECommerce", color: "rgba(62, 156, 245, 0.9)" }, 
    { name: "Healthcare", color: "rgba(255,255,0,0.8)" },    
    { name: "Fitness", color: "rgba(255, 182, 193, 0.8)" }, 
    { name: "Food Delivery", color: "rgba(62, 156, 245, 0.9)" },
    { name: "Real Estate", color: "rgba(255,255,0,0.8)" },
    { name: "Travel", color: "rgba(255, 182, 193, 0.8)" },
    { name: "Sports", color: "rgba(62, 156, 245, 0.9)" },
    { name: "Social Networking", color: "rgba(255,255,0,0.8)" },
    { name: "Business", color: "rgba(255, 182, 193, 0.8)" },
    { name: "Education", color: "rgba(62, 156, 245, 0.9)" },
    { name: "Entertainment", color: "rgba(255,255,0,0.8)" },
    { name: "Dating", color: "rgba(255, 182, 193, 0.8)" },
    { name: "Career", color: "rgba(62, 156, 245, 0.9)" },
    { name: "Transport", color: "rgba(255,255,0,0.8)" },
    { name: "Grocery", color: "rgba(255, 182, 193, 0.8)" },

    { name: "Fintech", color: "rgba(255, 182, 193, 0.8)" },
    { name: "ECommerce", color: "rgba(62, 156, 245, 0.9)" }, 
    { name: "Healthcare", color: "rgba(255,255,0,0.8)" },    
    { name: "Fitness", color: "rgba(255, 182, 193, 0.8)" }, 
    { name: "Food Delivery", color: "rgba(62, 156, 245, 0.9)" },
    { name: "Real Estate", color: "rgba(255,255,0,0.8)" },
    { name: "Travel", color: "rgba(255, 182, 193, 0.8)" },
    { name: "Sports", color: "rgba(62, 156, 245, 0.9)" },
    { name: "Social Networking", color: "rgba(255,255,0,0.8)" },
    { name: "Business", color: "rgba(255, 182, 193, 0.8)" },
    { name: "Education", color: "rgba(62, 156, 245, 0.9)" },
    { name: "Entertainment", color: "rgba(255,255,0,0.8)" },
    { name: "Dating", color: "rgba(255, 182, 193, 0.8)" },
    { name: "Career", color: "rgba(62, 156, 245, 0.9)" },
    { name: "Transport", color: "rgba(255,255,0,0.8)" },
    { name: "Grocery", color: "rgba(255, 182, 193, 0.8)" },
    { name: "Fintech", color: "rgba(255, 182, 193, 0.8)" },
    { name: "ECommerce", color: "rgba(62, 156, 245, 0.9)" }, 
    { name: "Healthcare", color: "rgba(255,255,0,0.8)" },    
    { name: "Fitness", color: "rgba(255, 182, 193, 0.8)" }, 
    { name: "Food Delivery", color: "rgba(62, 156, 245, 0.9)" },
    { name: "Real Estate", color: "rgba(255,255,0,0.8)" },
    { name: "Travel", color: "rgba(255, 182, 193, 0.8)" },
    { name: "Sports", color: "rgba(62, 156, 245, 0.9)" },
    { name: "Social Networking", color: "rgba(255,255,0,0.8)" },
    { name: "Business", color: "rgba(255, 182, 193, 0.8)" },
    { name: "Education", color: "rgba(62, 156, 245, 0.9)" },
    { name: "Entertainment", color: "rgba(255,255,0,0.8)" },
    { name: "Dating", color: "rgba(255, 182, 193, 0.8)" },
    { name: "Career", color: "rgba(62, 156, 245, 0.9)" },
    { name: "Transport", color: "rgba(255,255,0,0.8)" },
    { name: "Grocery", color: "rgba(255, 182, 193, 0.8)" },
  ];
  
  return (
    <div className={classes.container}>
        <div className={classes.listCon}>
          <div className={classes.titleCon}>
            <h1>Industries</h1>
            <p>Whether you're in e-commerce, healthcare, education, or entertainment, our expertise ensures a seamless digital experience that meets your unique needs. We understand the specific challenges and opportunities within each sector, enabling us to create impactful websites that drive engagement and growth.</p>
          </div>
        <div className={classes.list}>
        {list.map((ele, i) => {
            return <div className={classes.listItem}  key={i}><span className={classes.span} style={{background: `${ele.color}`}}></span><span className={classes.name}>{ele.name}</span></div>
        })}
        </div></div>
      </div>
  );
}

export default Industry;