import React, { useState } from 'react'
import classes from "./styles/SeoFaq.module.css";
import plus from "../assets/faq/add.png";
import minus from "../assets/faq/minus.png";
const SeoFaq = () => {
    const [box1 , setBox1] = useState(false);
    const [box2 , setBox2] = useState(false);
    const [box3 , setBox3] = useState(false);
    const [box4 , setBox4] = useState(false);
    const [box5 , setBox5] = useState(false);
    const [box6 , setBox6] = useState(false);

    const handlebox1 = () => {
        setBox1(!box1);
    }
    const handlebox2 = () => {
        setBox2(!box2);
    }

    const handlebox3 = () => {
        setBox3(!box3);
    }
    const handlebox4 = () => {
        setBox4(!box4);
    }
    const handlebox5 = () => {
        setBox5(!box5);
    }

    const handlebox6 = () => {
        setBox6(!box6);
    }


  return (
    <div className={classes.Container}>

        <div className={box1 ? classes.boxOpen : classes.box} onClick={handlebox1}>
            <div className={classes.upperCon}>
                <div>What SEO services do you provide?</div>
                <div className={classes.logoCon}><img src={box1 ? minus : plus} alt='plus-minus-icon'/></div>
            </div>
            <div className={classes.lowerCon}>
                <div>We specialize in technical and on-page SEO, including optimizing your website’s structure, improving site speed, meta tag optimization, and ensuring your site is search engine-friendly.</div>
            </div>
        </div>


        <div className={box2 ? classes.boxOpen : classes.box} onClick={handlebox2}>
            <div className={classes.upperCon}>
                <div>Do you offer off-page SEO or backlink building?</div>
                <div className={classes.logoCon}><img src={box2 ? minus : plus} alt='plus-minus-icon'/></div>
            </div>
            <div className={classes.lowerCon}>
                <div>No, we do not provide off-page SEO services such as backlink building or social media management. Our focus is solely on technical and on-page SEO improvements.</div>
            </div>
        </div>



        <div className={box3 ? classes.boxOpen : classes.box} onClick={handlebox3}>
            <div className={classes.upperCon}>
                <div>Do you provide content writing services?</div>
                <div className={classes.logoCon}><img src={box3 ? minus : plus} alt='plus-minus-icon'/></div>
            </div>
            <div className={classes.lowerCon}>
                <div>Currently, we do not offer content writing services. We can, however, optimize your existing content for SEO.</div>
            </div>
        </div>

        <div className={box4 ? classes.boxOpen : classes.box} onClick={handlebox4}>
            <div className={classes.upperCon}>
                <div>How long does it take to see results from technical and on-page SEO?</div>
                <div className={classes.logoCon}><img src={box4 ? minus : plus} alt='plus-minus-icon'/></div>
            </div>
            <div className={classes.lowerCon}>
                <div>SEO results can vary depending on the current state of your site, but typically, noticeable improvements in rankings and traffic may take a few weeks to several months.</div>
            </div>
        </div>

        <div className={box5 ? classes.boxOpen : classes.box} onClick={handlebox5}>
            <div className={classes.upperCon}>
                <div>Will your SEO services improve my site’s search engine rankings?</div>
                <div className={classes.logoCon}><img src={box5 ? minus : plus} alt='plus-minus-icon'/></div>
            </div>
            <div className={classes.lowerCon}>
                <div>Yes, our detailed technical and on-page SEO services are designed to improve your website's search engine visibility, making it more likely to rank higher for relevant search queries.</div>
            </div>
        </div>



        <div className={box6 ? classes.boxOpen : classes.box} onClick={handlebox6}>
            <div className={classes.upperCon}>
                <div>Do you provide ongoing SEO maintenance?</div>
                <div className={classes.logoCon}><img src={box6 ? minus : plus} alt='plus-minus-icon'/></div>
            </div>
            <div className={classes.lowerCon}>
                <div>Yes, we offer ongoing SEO support to ensure your website remains optimized as search engine algorithms and best practices evolve.</div>
            </div>
        </div>

    </div>
  )
}

export default SeoFaq;
