import React, { useState } from 'react'
import classes from "./styles/footer.module.css";
import logo from "../assets/SuperworkW.png"
import { useNavigate } from 'react-router-dom';
const Footer = ({contactSection, faqSection,packageSection}) => {
  const navigate = useNavigate();
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const scrollToContact = () => {
    const element = document.getElementById(contactSection);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }else{
      navigate("/");
      const delayImport = () => {
        const element2 = document.getElementById(contactSection);
        element2.scrollIntoView({ behavior: 'smooth' });
      };
      const timeoutId = setTimeout(() => {
        delayImport();
      }, 300);
      return () => clearTimeout(timeoutId);
    }
  };

  const scrollToFaq = () => {
    const element = document.getElementById(faqSection);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }else{
      navigate("/");
      const delayImport = () => {
        const element2 = document.getElementById(faqSection);
        element2.scrollIntoView({ behavior: 'smooth' });
      };
      const timeoutId = setTimeout(() => {
        delayImport();
      }, 300);
      return () => clearTimeout(timeoutId);
    }
  };

  const SrolltoPackage = () => {
    const element = document.getElementById(packageSection);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }else{
      navigate("/");
      const delayImport = () => {
        const element2 = document.getElementById(packageSection);
        element2.scrollIntoView({ behavior: 'smooth' });
      };
      const timeoutId = setTimeout(() => {
        delayImport();
      }, 300);
      return () => clearTimeout(timeoutId);
    }
  };

  const handleopenMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  }

  return (
    <div className={classes.container}>
      <div className={classes.upper}><a href='/'><img src={logo} className={classes.logo} alt='superworq-logo'/></a> &nbsp; © 2024 All right reserved.</div>
      <div className={classes.lower}>
      <button onClick={() => window.open('#/about', '_blank', 'noopener,noreferrer')}>
  About
</button>
        {/* <a href='#/about' className={classes.about}>About</a> */}
        <div className={classes.dropdown}>
        <button onClick={handleopenMenu} className={classes.serviceTitle}>Services</button>
        {isMenuOpen && <div className={classes.services}>
          <a href='#/wordpress-website-maintenance' target='_blank'>Maintenance</a>
          <a href='#/wordpress-website-modification' target='_blank'>Modification</a>
          <a href='#/wordpress-website-seo' target='_blank'>SEO</a>
        </div>}
        </div>
        <button onClick={scrollToContact}>Contact</button>
        <button onClick={SrolltoPackage}>Pricing</button>
        <button onClick={scrollToFaq}>FAQ</button>
        <button onClick={() => window.open('https://www.wiraa.com/#/privacy-policy', '_blank', 'noopener,noreferrer')}>
  Privacy
</button>
      </div>
    </div>
  )
}

export default Footer
