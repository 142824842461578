import React, { useEffect, useState } from 'react';
import classes from "./Styles/modification.module.css";
import Navbar from '../components/Navbar';
import Img2 from "../assets/modification/1.png";
import Img1 from "../assets/modification/2.png";

import customDesign from "../assets/modification/design.png";
import functionality from "../assets/modification/functionality.png";
import seo from "../assets/modification/seo.png";
import performance from "../assets/modification/performance.png";
import integration from "../assets/modification/integration.png";
import responsive from "../assets/modification/device.png";

import cool from "../assets/modification/3.png";

import check from "../assets/check.svg";
import Footer from '../components/Footer';
import ModificationFaq from '../aboutSections/ModificationFaq';

function Modifications() {
  const [opacity, setOpacity] = useState(0);

  const handleButtonClick1 = () => {
    const queryParams = new URLSearchParams({
      color: '#EFEFEF',
      price: "249",
      plan: 'Basic',
      time: ''
    }).toString();
    window.open(`/#/checkout?${queryParams}`, '_blank');
  };

  const handleButtonClick2 = () => {
    const queryParams = new URLSearchParams({
      color: '#EFEFEF',
      price: "599",
      plan: 'Grow',
      time: ''
    }).toString();
    window.open(`/#/checkout?${queryParams}`, '_blank');
  };

  const handleButtonClick3 = () => {
    const queryParams = new URLSearchParams({
      color: '#FFCCDD',
      price: "999",
      plan: 'Scale',
      time: ''
    }).toString();
    window.open(`/#/checkout?${queryParams}`, '_blank');
  };


  useEffect(() => {
    document.title = "Wordpress Website Modifications";
    const metaDescription = document.querySelector('meta[name="description"]');
    if (metaDescription) {
      metaDescription.setAttribute('content', 'Tailored WordPress Website Modifications for Enhanced Functionality.');
    } else {
      const newMetaDescription = document.createElement('meta');
      newMetaDescription.name = 'description';
      newMetaDescription.content = 'Tailored WordPress Website Modifications for Enhanced Functionality.';
      document.getElementsByTagName('head')[0].appendChild(newMetaDescription);
    }
  }, []);


  const handleScroll = () => {
    const scrollTop = window.pageYOffset;
    const windowHeight = window.innerHeight;
    const element = document.querySelector(".scroll-container");
  
    // Check if the element exists
    if (!element) return;
  
    const elementTop = element.getBoundingClientRect().top + scrollTop - 100;
    const elementHeight = element.offsetHeight;
    const startFade = elementTop - windowHeight;
    const endFade = elementTop + elementHeight;
  
    if (scrollTop > startFade && scrollTop < endFade) {
      const opacityValue = (scrollTop - startFade) / (endFade - startFade);
      setOpacity(opacityValue);
    } else if (scrollTop >= endFade) {
      setOpacity(1);
    } else {
      setOpacity(0);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  // useEffect(() => {
  //   const timeoutId = setTimeout(() => {
  //     import('./canvas.js');
  //     console.log("This function is called!");
  //   }, 100);
  //   return () => clearTimeout(timeoutId);
  // }, []);

  const scrollToPackage = () => {
    const element = document.getElementById('priceSection');
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (

  <div className={classes.container}>
  <Navbar contactSection="contactSection" faqSection="faqSection" packageSection="packageSection"/>
  <div className={classes.background}><canvas id="test"></canvas></div>
  <div className={classes.firstDiv}>
    <div className={classes.heading}>Customization Made Easy</div>
    <div className={classes.title}>Tailored WordPress Website Modifications for Enhanced Functionality</div>
    <div className={classes.subTitle}>Transform your WordPress site to meet your evolving business needs with our expert modification services. From adding features to customizing design and optimizing performance, we ensure your site looks great and functions flawlessly.</div>
    <div className={classes.btnDiv}><button className={classes.contactBtn} onClick={scrollToPackage}>See Plans</button></div>
  </div>


  {/* -----------------------What is website maintenance section ------------------------ */}

  <div className={classes.vector1Con}>
    <div className={classes.vector1left}>
      <img src={Img1} alt='vector-img'/>
    </div>
    <div className={classes.vector1right}>
      <div className={classes.question}>Why modify your WordPress website?</div>
      <div className={classes.ans}>Modifying your WordPress site is key to staying competitive and meeting your evolving business needs. Enhancements can improve functionality, user experience, and design, ensuring your website effectively supports your goals.</div>
    </div>
  </div>

  {/* -----------------------Why is  website maintenance important section ------------------------ */}


  <div className={classes.vector2Con}>
    <div className={classes.vector2left}>
      <div className={classes.question}>When should you consider modifications?</div>
      <div className={classes.ans}>Consider modifying your website when you experience performance issues, shifts in user behavior, or changes in your business strategy. Regularly assessing your site helps ensure it adapts and remains effective for your audience.</div>
    </div>
    <div className={classes.vector2right}>
      <img src={Img2} alt='vector-img'/>
    </div>
  </div>

  


  {/* -----------------------Benfits container section ------------------------ */}

  <div className={`scroll-container ${classes.featureCon}`}>
    <div className={classes.featureInnerCon} style={{ background: `rgba(255,240,224, ${opacity})` }}>
      <div className={classes.featureTitle}>Customize, Enhance, Improve & Succeed!</div>



      <div className={classes.featureDivLine}>
  <div className={classes.featureDiv}>
    <div className={classes.featureLogo}><img src={customDesign} alt='custom-design-icon'/></div>
    <div className={classes.featureName}>Custom Design</div>
    <div className={classes.featurePara}>Tailor your site’s appearance to match your brand</div>
  </div>

  <div className={classes.featureDiv}>
    <div className={classes.featureLogo}><img src={functionality} alt='enhanced-functionality-icon'/></div>
    <div className={classes.featureName}>Enhanced Functionality</div>
    <div className={classes.featurePara}>Add new features & capabilities</div>
  </div>

  <div className={classes.featureDiv}>
    <div className={classes.featureLogo}><img src={seo} alt='seo-optimization-icon'/></div>
    <div className={classes.featureName}>SEO Optimization</div>
    <div className={classes.featurePara}>Improve search engine visibility & rankings</div>
  </div>
</div>

<div className={classes.featureDivLine}>
  <div className={classes.featureDiv}>
    <div className={classes.featureLogo}><img src={performance} alt='performance-boost-icon'/></div>
    <div className={classes.featureName}>Performance Boost</div>
    <div className={classes.featurePara}>Optimize for faster load times & better experience</div>
  </div>

  <div className={classes.featureDiv}>
    <div className={classes.featureLogo}><img src={integration} alt='third-party-integration-icon'/></div>
    <div className={classes.featureName}>Third-Party Integrations</div>
    <div className={classes.featurePara}>Integrate with tools & services you rely on</div>
  </div>

  <div className={classes.featureDiv}>
    <div className={classes.featureLogo}><img src={responsive} alt='responsive-design-icon'/></div>
    <div className={classes.featureName}>Responsive Design</div>
    <div className={classes.featurePara}>Ensure your site looks great on all devices</div>
  </div>
</div>

        </div>
        </div>

{/* -----------------------Steps ------------------------ */}

<div className={classes.stepCon}>
      
      <div className={classes.stepTitle}>How Our Modification Process Works</div>
      <div className={classes.stepSubTitle}>Get comprehensive modification for your WordPress website in 3 easy steps.</div>
      <div className={classes.stepInnerCon}>
      <div className={classes.step1Div}>
        <div className={classes.stepImgCon}><img src={cool} alt='step-img'/></div>
      </div>


      <div className={classes.step1Div}>

      <div className={classes.stepContentDiv}>
        <div className={classes.stepSubname}>1</div>
        <div className={classes.stepName}>Choose a Modification Plan</div>
        </div>
        <div className={classes.stepContentDiv}>
        <div className={classes.stepSubname}>2</div>
        <div className={classes.stepName}>Instant access to our dev team for immediate task support!</div>
        </div>

        <div className={classes.stepContentDiv}>
        <div className={classes.stepSubname}>3</div>
        <div className={classes.stepName}>Place as many orders as you need, and we'll complete them within 1-3 business days</div>
      </div>
      </div>


    </div>
  </div>


{/* -----------------------Packages section ------------------------ */}

<div className={`${classes.packageCon} `} id='priceSection'>
<div className={classes.packageShape}><span>Best Value</span></div>
  <div className={`${classes.packageDiv}`}>
    <div className={classes.upperPackage}>
    <div className={classes.packageName}>Basic</div>
    <div className={classes.packagePrice}>$249</div>
    <div className={classes.packageSubtitle}>Up to 10 hours support</div>
    <div className={classes.packageBtnCon}><button className={classes.packageBtn} onClick={handleButtonClick1}>Get Started</button></div>
    </div>
    <div className={classes.lowerPackage}>
    <div className={classes.packageItem}><img src={check} alt='right-tick-icon'/><span>Best for quick tweaks and essential updates</span></div>
    <div className={classes.packageItem}><img src={check} alt='right-tick-icon'/><span>Blog modifications (e.g., adding posts, fixing layouts)</span></div>
    <div className={classes.packageItem}><img src={check} alt='right-tick-icon'/><span>Basic SEO improvements (meta tags, keywords)</span></div>
    <div className={classes.packageItem}><img src={check} alt='right-tick-icon'/><span>Plugin installations & basic configuration</span></div>
    <div className={classes.packageItem}><img src={check} alt='right-tick-icon'/><span>Content updates (text, images)</span></div>
    <div className={classes.packageItem}><img src={check} alt='right-tick-icon'/><span>Minor bug fixes & Speed optimization</span></div>
    <div className={classes.packageItem}><img src={check} alt='right-tick-icon'/><span>Social media integration (sharing buttons)</span></div>
    <div className={classes.packageItem}><img src={check} alt='right-tick-icon'/><span>Minor design or layout adjustments</span></div>
    <div className={classes.packageItem}><img src={check} alt='right-tick-icon'/><span>Simple form setup (e.g., contact forms)</span></div>
    </div>
  </div>


  <div className={`${classes.packageDiv}`}>
    <div className={classes.upperPackage}>
    <div className={classes.packageName}>Grow</div>
    <div className={classes.packagePrice}>$599</div>
    <div className={classes.packageSubtitle}>Up to 25 hours support</div>
    <div className={classes.packageBtnCon}><button className={classes.packageBtn} onClick={handleButtonClick2}>Get Started</button></div>
    </div>
    <div className={classes.lowerPackage}>
    <div className={classes.packageItem}><img src={check} alt='right-tick-icon'/><span>Includes everything in the Basic package, plus:</span></div>
    <div className={classes.packageItem}><img src={check} alt='right-tick-icon'/><span>eCommerce modifications (product categories, payment gateways)</span></div>
    <div className={classes.packageItem}><img src={check} alt='right-tick-icon'/><span>Advanced SEO (on-page SEO, performance improvements)</span></div>
    <div className={classes.packageItem}><img src={check} alt='right-tick-icon'/><span>Custom CSS & branding updates</span></div>
    <div className={classes.packageItem}><img src={check} alt='right-tick-icon'/><span>Mobile responsiveness optimization</span></div>
    <div className={classes.packageItem}><img src={check} alt='right-tick-icon'/><span>Advanced plugin setup (e.g., booking systems)</span></div>
    <div className={classes.packageItem}><img src={check} alt='right-tick-icon'/><span>Setup of additional forms (feedback, surveys)</span></div>
    <div className={classes.packageItem}><img src={check} alt='right-tick-icon'/><span>More complex bug fixes or troubleshooting</span></div>
    <div className={classes.packageItem}><img src={check} alt='right-tick-icon'/><span>Backend optimizations (e.g., database cleanups)</span></div>
    </div>
  </div>


  <div className={`${classes.packageDiv} ${classes.p2}`}>
    <div className={classes.upperPackage2}>
    <div className={classes.packageName2}>Scale</div>
    <div className={classes.packagePrice2}>$999</div>
    <div className={classes.packageSubtitle2}>Up to 50 hours support</div>
    <div className={classes.packageBtnCon}><button className={classes.packageBtn2} onClick={handleButtonClick3}>Get Started</button></div>
    </div>
    <div className={classes.lowerPackage}>
    <div className={classes.packageItem}><img src={check} alt='right-tick-icon'/><span>Includes everything in the Grow, plus:</span></div>
    <div className={classes.packageItem}><img src={check} alt='right-tick-icon'/><span>Full eCommerce setup or upgrades (multi-language support, shipping)</span></div>
    <div className={classes.packageItem}><img src={check} alt='right-tick-icon'/><span>Custom coding for unique features or complex plugins</span></div>
    <div className={classes.packageItem}><img src={check} alt='right-tick-icon'/><span>Major SEO overhauls (URL structure, site architecture)</span></div>
    <div className={classes.packageItem}><img src={check} alt='right-tick-icon'/><span>Integration with third-party services (CRMs, API connections)</span></div>
    <div className={classes.packageItem}><img src={check} alt='right-tick-icon'/><span>Advanced security enhancements & backup solutions</span></div>
    <div className={classes.packageItem}><img src={check} alt='right-tick-icon'/><span>Technical audits & performance optimizations for heavy traffic</span></div>
    <div className={classes.packageItem}><img src={check} alt='right-tick-icon'/><span>Creation of new custom features & designs</span></div>
    <div className={classes.packageItem}><img src={check} alt='right-tick-icon'/><span>Comprehensive website updates & modifications</span></div>
    </div>
  </div>
</div>
<div className={classes.FaqCon}><ModificationFaq/></div>

<div className={classes.footerCon}>
      <Footer contactSection="contactSection" faqSection="faqSection" packageSection="packageSection"/>
    </div>
    
  </div>)
}

export default Modifications;