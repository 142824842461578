import React, { useState } from 'react';
import logo from "../assets/Superworq.png"
import classes from "./styles/navbar.module.css";
import {useNavigate } from 'react-router-dom';
import menuIcon from "../assets/menu.png";

function Navbar({contactSection, faqSection,packageSection}) {
  const navigate = useNavigate();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  const scrollToContact = () => {
    const element = document.getElementById(contactSection);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }else{
      navigate("/");
      const delayImport = () => {
        const element2 = document.getElementById(contactSection);
        element2.scrollIntoView({ behavior: 'smooth' });
      };
      const timeoutId = setTimeout(() => {
        delayImport();
      }, 300);
      return () => clearTimeout(timeoutId);
    }
  };

  const scrollToFaq = () => {
    const element = document.getElementById(faqSection);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }else{
      navigate("/");
      const delayImport = () => {
        const element2 = document.getElementById(faqSection);
        element2.scrollIntoView({ behavior: 'smooth' });
      };
      const timeoutId = setTimeout(() => {
        delayImport();
      }, 300);
      return () => clearTimeout(timeoutId);
    }
  };

  const SrolltoPackage = () => {
    const element = document.getElementById(packageSection);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }else{
      navigate("/");
      const delayImport = () => {
        const element2 = document.getElementById(packageSection);
        element2.scrollIntoView({ behavior: 'smooth' });
      };
      const timeoutId = setTimeout(() => {
        delayImport();
      }, 300);
      return () => clearTimeout(timeoutId);
    }
  };

  const handleopenMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  }
  const handleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  }
  return (
  <>
  <div className={classes.Container}>
    <div className={classes.firstCon}>
        <a href='/'><img src={logo} className={classes.logoImg} alt='superworq-logo'/></a>
    </div>
    <div className={classes.secondCon}>
        <a href='#/about'>About</a>
        <div className={classes.dropdown}>
        <button onClick={handleopenMenu} className={classes.serviceTitle}>Services</button>
        {isMenuOpen && <div className={classes.services}>
          <a href='#/wordpress-website-maintenance'>Maintenance</a>
          <a href='#/wordpress-website-modification'>Modification</a>
          <a href='#/wordpress-website-seo'>SEO</a>
        </div>}
        </div>
        <button onClick={scrollToContact}>Contact</button>
        <button onClick={SrolltoPackage}>Pricing</button>
        <button onClick={scrollToFaq}>FAQ</button>
    </div>
  <div className={classes.secondConMobile}>
    <div onClick={handleMobileMenu} className={classes.menuIcon}><img src={menuIcon} alt='hamburger-icon'/></div>
  {isMobileMenuOpen && <div className={classes.dropdownMobile}>
        <a href='#/about'>About</a>
        <a href='#/wordpress-website-maintenance'>Maintenance</a>
        <a href='#/wordpress-website-modification'>Modification</a>
        <a href='#/wordpress-website-seo'>SEO</a>
        <button onClick={scrollToContact}>Contact</button>
        <button onClick={SrolltoPackage}>Pricing</button>
        <button onClick={scrollToFaq}>FAQ</button>
    </div>}
  </div>
  </div>
  </>
);
}

export default Navbar;